import { Card, CardContent, Button, IconButton, SvgIcon } from "@mui/material";
import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import CircularWithValueLabel from "./ProgressCircle2";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import FileOpenOutlinedIcon from "@mui/icons-material/FileOpenOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const Item = ({ field, value, isProgressCircle = false }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box width="100%" m="0 10px">
      <Box
        display="flex"
        justifyContent="space-between"
        mt="15px"
        borderBottom="0.01px #D0D2D2 solid"
        alignItems="center"
      >
        <Typography sx={{ color: colors.grey[300] }} fontSize="5">
          {field}
        </Typography>

        {/* Corrected the ternary operator */}
        {isProgressCircle ? (
          <Box>
            <CircularWithValueLabel value={value} />
          </Box>
        ) : (
          <Typography fontSize="5" sx={{ color: colors.grey[100] }}>
            {value}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

const DealCard = ({
  title,
  subtitle,
  totaldealsize,
  spread,
  minimumparticiapationamount,
  duration,
  closedate,
  progress,
  segment,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Card sx={{ minWidth: 275 }} className="text-center">
      <CardContent
        display="flex"
        justifyContent="space-between"
        sx={{
          width: "100%",
          height: "100%",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: 1,
          display: "inline-flex",
        }}
      >
        <Typography
          variant="h4"
          fontWeight="bold"
          sx={{ color: colors.grey[100] }}
        >
          {title}
        </Typography>
        <Typography variant="h6" sx={{ color: colors.grey[200] }}>
          {subtitle}
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: 2,
            mt: 1,
            paddingLeft: 1,
            paddingRight: 1,
            paddingTop: 0.8,
            paddingBottom: 0.8,
            border: "0.2px #D0D2D2 solid",
            borderRadius: 1.2,
            "& > button": { borderRadius: "2rem" },
          }}
        >
          <Typography
            textAlign="center"
            variant="h7"
            sx={{ color: colors.grey[100] }}
          >
            {segment}
          </Typography>
        </Box>
        <Item
          field="Deal Size"
          value={`${Math.round(totaldealsize) / 1000000}M`}
        />
        <Item field="Spread" value={`${spread}%`} />
        {/* <Item field="IRR" value="10%"/> */}
        <Item field="Rating" value="A+" />
        <Item
          field="Participation Amount"
          value={`${Math.round(minimumparticiapationamount) / 1000}K`}
        />
        <Item field="Duration" value={duration} />
        <Item
          field="Close Date"
          value={new Date(closedate).toLocaleDateString("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          })}
        />
        <Item
          field="Deal Status"
          value={progress * 100}
          isProgressCircle={true}
        />

        <Box sx={{ mt: 1.5 }}>
          <Typography
            textAlign="center"
            variant="h7"
            sx={{ color: colors.grey[100] }}
          >
            Attachments
          </Typography>
          <Box justifyContent="space-evenly">
            <IconButton>
              <PictureAsPdfOutlinedIcon
                sx={{ fontSize: "20px", color: colors.greenAccent[500] }}
              />
            </IconButton>
            <IconButton>
              <FileOpenOutlinedIcon
                sx={{ fontSize: "20px", color: colors.greenAccent[500] }}
              />
            </IconButton>
          </Box>
        </Box>
        <Box justifyContent="space-evenly" sx={{ mt: 1 }}>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[600],
              color: colors.grey[100],
              fontSize: "10px",
              fontWeight: "bold",
              padding: "10px 15px",
            }}
          >
            <InfoOutlinedIcon sx={{ mr: "5px" }} />
            Transaction Details
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default DealCard;
