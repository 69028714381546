import { Box, Button, TextField, Select,MenuItem } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../../components/Header";
import InputAdornment from '@mui/material/InputAdornment';
import { usePostNotesDBMutation } from "../../../state/api";
import Alert from '@mui/material/Alert';
import { LoadingButton } from '@mui/lab';
import { useState } from "react";
import { styled } from '@mui/material/styles';



const Form = ({onNoteAdded}) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [postNotesDB, { isLoading, isSuccess, isError }] = usePostNotesDBMutation();
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(false);
  const [currency, setCurrency] = useState('ILS');

  const StyledSelect = styled(Select)({
    // Remove native select dropdown arrow
    '&.MuiNativeSelect-select': {
      paddingRight: 0,
    },
    // Remove select field outline
    '&& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    // Remove hover effect
    '&:hover:not(.Mui-disabled):before': {
      border: 'none',
    },
    // Style for the dropdown icon
    '&& .MuiSvgIcon-root': {
      display: 'none', // Hide dropdown icon
    },
  });

  const handleCurrencyChange = (event) => {
    setCurrency(event.target.value);
  };

  const handleFormSubmit = async (values, { resetForm }) => {
    console.log(values);
    setLoading(true);

    try {
        const newNote = await postNotesDB(values).unwrap();
        console.log('Note uploaded successfully');
        setAlert({ show: true, message: 'Note was uploaded successfully.', severity: 'success' });
        setLoading(false);
        console.log("frontend note", newNote)
        onNoteAdded(newNote.data)
        resetForm();
        
        // Handle success, e.g., showing a success message or redirecting
        } catch (error) {
            console.error('Error submitting the form:', error);
            setLoading(false);
            setAlert({ show: true, message: 'Error check required fields and ISIN', severity: 'error' });
        // Handle error, e.g., showing an error message
        }
    };    

  return (
    <Box m="20px">
      <Header title="UPLOAD NOTE" subtitle="Upload Note" />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.name}
                name="name"
                error={!!touched.name && !!errors.name}
                helperText={touched.name && errors.name}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Description"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.description}
                name="description"
                error={!!touched.description && !!errors.description}
                helperText={touched.description && errors.description}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                id="standard-start-adornment"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                type="date"
                label="Expiration Date"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.expirationDate}
                name="expirationDate"
                error={!!touched.expirationDate && !!errors.expirationDate}
                helperText={touched.expirationDate && errors.expirationDate}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Amount"
                // InputProps={{
                //     startAdornment: <InputAdornment position="start">$</InputAdornment>,
                //   }}        
          //       InputProps={{
          //         startAdornment: (
          //           <InputAdornment position="start">
          //             <StyledSelect
          //    value={currency}
          //    onChange={handleCurrencyChange}
          //    displayEmpty
          //    inputProps={{ 'aria-label': 'Currency' }}
          //    renderValue={selected => selected}
          //  >
          //   <MenuItem value="ILS">ILS</MenuItem>
          //    <MenuItem value="$">$</MenuItem>
          //    <MenuItem value="€">€</MenuItem>
          //    <MenuItem value="¥">¥</MenuItem>
          //               {/* ...other options */}
          //             </StyledSelect>
          //           </InputAdornment>
          //         ),
          //         // ...other InputProps
          //       }}                        
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.amount}
                name="amount"
                error={!!touched.amount && !!errors.amount}
                helperText={touched.amount && errors.amount}
                sx={{ gridColumn: "span 2" }}
              />
                <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Price"
                // InputProps={{
                //     startAdornment: <InputAdornment position="start">ILS</InputAdornment>,
                //   }}                         
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.price}
                name="price"
                error={!!touched.price && !!errors.price}
                helperText={touched.price && errors.price}
                sx={{ gridColumn: "span 2" }}
              />

<TextField
                  fullWidth
                  variant="filled"
                  select                  
                  label="Currency"    
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.investmentprofile}
                  name="currency"
                  error={!!touched.currency && !!errors.currency}
                  helperText={touched.currency && errors.currency}
                  sx={{ gridColumn: "span 2" }}
            >
                <MenuItem key="1" value="ILS">
                  ILS
                </MenuItem>
                <MenuItem key="2" value="$">
                  $
                </MenuItem>
                <MenuItem key="3" value="€">
                  €
                </MenuItem>
                <MenuItem key="4" value="£">
                £
                </MenuItem>

            </TextField>

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Coupon"
                InputProps={{
                    startAdornment: <InputAdornment position="start">%</InputAdornment>,
                  }}                         
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.coupon}
                name="coupon"
                error={!!touched.coupon && !!errors.coupon}
                helperText={touched.coupon && errors.coupon}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Underlying"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.underlying}
                name="underlying"
                error={!!touched.underlying && !!errors.underlying}
                helperText={touched.underlying && errors.underlying}
                sx={{ gridColumn: "span 2" }}
              />

            <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Barrier"
                InputProps={{
                    startAdornment: <InputAdornment position="start">%</InputAdornment>,
                  }}                     
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.barrier}
                name="barrier"
                error={!!touched.barrier && !!errors.barrier}
                helperText={touched.barrier && errors.barrier}
                sx={{ gridColumn: "span 2" }}
              /> 
            <TextField
                fullWidth
                variant="filled"
                type="text"
                label="ISIN"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.isin}
                name="isin"
                error={!!touched.isin && !!errors.isin}
                helperText={touched.isin && errors.isin}
                sx={{ gridColumn: "span 2" }}
              />                   

            </Box>
            <Box display="flex" justifyContent="end" mt="20px">

            <LoadingButton
        type="submit"        
        loading={loading}
        color="secondary"
        variant="contained"
      >
        Submit Note
      </LoadingButton>
      {alert.show && <Alert severity={alert.severity}>{alert.message}</Alert>}
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

// const phoneRegExp =
//   /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
  name: yup.string().required("required"),
  description: yup.string().required("required"),
  expirationDate: yup.date().required("invalid date"),
  amount: yup.number().required("required"),
  price: yup.number().required("required"),
  currency: yup.string().required("required"),
  coupon: yup.number().required("required"),
  underlying: yup.string().required("required"),
  barrier: yup.number().optional("optional"),
  isin: yup.string().optional("optional"),
});
const initialValues = {
  name: "",
  description: "",
  expirationDate: "",
  amount: "",
  price:"",
  currency: "",
  coupon: "",
  underlying: "",
  barrier: "",
  isin: "",
};

export default Form;