import React from 'react'
import { Box, Typography, Dialog, DialogContent } from "@mui/material";
import {
  DataGrid,
  GridToolbar,
  GridRowModes,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from "@mui/x-data-grid";
import { useEffect } from "react";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme,CircularProgress } from "@mui/material";
import { useMobile } from '../../MobileContext';
import { useGetRequestsDataAdminQuery,useModifyRequestMutation,useDeleteRequestMutation,useGetRequestsDataQuery } from "../../state/api";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import Button from "@mui/material/Button";
import AddRequestPage from "../forms/addRequest";
import { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import {useUser} from '../../UserContext';


const Requests = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isMobile = useMobile()
    const {user,userData,setUserData} = useUser();
    const role = userData ? userData.rows[0].role :  'nan';
    const investorId = userData ? userData.rows[0].investorid :  'nan';
    const { data, isLoading } = useRequestsData(role,investorId)
    const [updateRequest, { isLoadingUpdate, isSuccess, isError }] =
    useModifyRequestMutation();
    const [deleteRequest, { isLoadingDelete, isSuccessDelete, isErrorDelete }] =
      useDeleteRequestMutation();

    const [open, setOpen] = useState(false);
    const [rows, setRows] = useState([]);
    const [rowModesModel, setRowModesModel] = useState({});


    function useRequestsData(userRole,investorId) {
      const adminQueryResult = useGetRequestsDataAdminQuery({investorId});
      const userQueryResult = useGetRequestsDataQuery({investorId});

      if (userRole === 'admin') {

        return adminQueryResult;

      } else {
        return userQueryResult;
      }
    }


  
    useEffect(() => {
      if (!isLoading && data) {
        setRows(data ?? []); // Assuming 'data' is in the correct format for DataGrid
      }
    }, [data]);

  
    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };
  
    const handleRequestAllocation = (newRequest) => {
      // queryClient.invalidateQueries('getDashboardDataAdmin');
      setRows((prevRows) => [...prevRows, newRequest]);
    };
  
    const handleRowEditStop = (params, event) => {
      if (params.reason === GridRowEditStopReasons.rowFocusOut) {
        event.defaultMuiPrevented = true;
      }
    };
  
    const handleEditClick = (id) => () => {
      console.log("handle edit click", id);
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };
  
    const handleSaveClick = (id) => () => {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };
  
    const handleDeleteClick = (id) => async () => {
      try {
        // If deleteNote is successful, filter out the deleted row
        setRows(rows.filter((row) => row.requestid !== id));
        // Assuming deleteNote makes an API call and returns a promise
        await deleteRequest(id);
      } catch (error) {
        console.error("Failed to delete note", error);
        // Handle any errors, e.g., showing an error message to the user
      }
    };
  
    const handleCancelClick = (id) => () => {
      setRowModesModel({
        ...rowModesModel,
        [id]: { mode: GridRowModes.View, ignoreModifications: true },
      });
      const editedRow = rows.find((row) => row.requestid === id);
      if (editedRow.isNew) {
        setRows(rows.filter((row) => row.requestid !== id));
      }
    };
  
    const processRowUpdate = async (newRow) => {
      const updatedRow = { ...newRow, isNew: false };
      try {
        await updateRequest(updatedRow).unwrap();
      } catch (error) {
        console.error("Failed to update investment", error);
      }
      setRows(
        rows.map((row) =>
          row.requestid === newRow.requestid ? updatedRow : row
        )
      );
      return updatedRow;
    };
  
    const handleRowModesModelChange = (newRowModesModel) => {
      setRowModesModel(newRowModesModel);
    };
  
    const columns = [
      { field: "requestid", headerName: "ID", flex: 0.5 },
      // { field: "registrarId", headerName: "Registrar ID" },
      {
        field: "investor_name",
        headerName: "Investor",
        flex: 1,
        cellClassName: "name-column--cell",
        // editable: true,
      },
      {
        field: "note_name",
        headerName: "Note",
        flex: 1,
        headerAlign: "left",
        align: "left",
        // editable: true,
      },
      {
        field: "investmentdate",
        headerName: "Investment Date",
        headerAlign: "left",
        align: "left",
        valueGetter: ({ value }) => value && new Date(value),
        type: "date",
        flex: 1,
        editable: true,
      },
      {
        field: "investmentamount",
        headerName: "Investment ($)",
        headerAlign: "left",
        align: "left",
        type: "number",
        flex: 1,
        editable: true,
      },
      {
        field: "amount",
        headerName: "Note Size",
        headerAlign: "left",
        align: "left",
        flex: 1,
        type: "number",
        valueFormatter: (params) => {
          if (params.value == null) {
            return "";
          }
          return `${(params.value / 1000000).toLocaleString()}M$`;
        },
      },
    //   {
    //     field: "expirationdate",
    //     headerName: "Expiration Date",
    //     headerAlign: "left",
    //     align: "left",
    //     flex: 1,
    //     type: "date",
    //     valueGetter: ({ value }) => value && new Date(value),
    //   },
      {
        field: "coupon",
        headerName: "Coupon %",
        headerAlign: "left",
        align: "left",
        flex: 1,
        type: "number",
        valueFormatter: (params) => {
          if (params.value == null) {
            return "";
          }
          return `${params.value.toLocaleString()} %`;
        },
      },
      {
        field: "isin",
        headerName: "ISIN",
        headerAlign: "left",
        align: "left",
        flex: 1,
      },
      {
        field: "status",
        headerName: "Status",
        flex: 0.5,
        type: "singleSelect",
        valueOptions: ["Pending", "Decline", "Approve"],
        editable: role === "admin" // Conditionally set the editable property based on role
     },
    
{
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    }, 
];
  
    return (
      <Box m="20px">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Header title="REQUESTS" />

          <Box>
            <Button
              onClick={handleClickOpen}
              sx={{
                backgroundColor: colors.greenAccent[500],
                color: colors.grey[100],
                fontSize: "10px",
                fontWeight: "bold",
                padding: "10px 20px",
                mr: "20px",
              }}
            >
              <AddOutlinedIcon sx={{ mr: "10px" }} />
              Open Request
            </Button>
            <Dialog open={open} onClose={handleClose}>
              <DialogContent>
                <AddRequestPage
                  onClose={handleClose}
                  onRequestAdded={handleRequestAllocation}
                />
              </DialogContent>
            </Dialog>
          </Box>
        </Box>
        {rows || !isLoading ? (
          <Box
            m="40px 0 0 0"
            height="75vh"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .name-column--cell": {
                color: colors.greenAccent[300],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.greenAccent[700],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.greenAccent[700],
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${colors.grey[100]} !important`,
              },
            }}
          >
            <DataGrid
              rows={rows}
              columns={columns}
              editMode="row"
              getRowId={(row) => row.requestid}
              rowModesModel={rowModesModel}
              components={{ Toolbar: GridToolbar }}
              onRowModesModelChange={handleRowModesModelChange}
              onRowEditStop={handleRowEditStop}
              processRowUpdate={processRowUpdate}
              style={{height: '100%',
              fontSize: isMobile ? '8px' : '10px'}}
            />
          </Box>
        ) : (
          <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="50vh"
          // width="100vw"
      >
          <CircularProgress />
      </Box>         )}
      </Box>
    );
  };

export default Requests