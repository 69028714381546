import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import { Typography, Box, useTheme, IconButton } from "@mui/material";
import { tokens } from "../theme"; 


  export default function PrimarySearchAppBar() {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
return (

    <Box

    display="flex"
    backgroundColor={colors.primary[400]}
    borderRadius="2px"
  >
    <InputBase sx={{ ml: 2, flex: 1 }} placeholder="Search" />
    <IconButton type="button" sx={{ p: 1 }}>
      <SearchIcon />
    </IconButton>
  </Box>
)  


}
