import { ResponsiveLine } from "@nivo/line";
import { useTheme,Box } from "@mui/material";
import { tokens } from "../theme";
import { mockLineData as data } from "../data/mockData";
import { useMobile } from "../MobileContext";

function getFilteredSeriesData(data, isMobile) {
  // Define the number of ticks based on the device type
  const ticks = isMobile ? 10 : 7;

  // Map over the series data to apply conditional filtering
  const filteredData = data.map(series => {
      // Check if the series data length is less than or equal to the number of ticks
      if (series.data.length <= ticks) {
          return { ...series }; // Return the series as is if data points are fewer than ticks
      } else {
          // Filter the series data to include every 'ticks'-th element
          const filteredSeriesData = series.data.filter((_, index) => index % ticks === 0);
          return {
              ...series,
              data: filteredSeriesData
          };
      }
  });

  return filteredData;
}

const LineChart = ({ idLine,isCustomLineColors = false, isDashboard = false, data }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useMobile()
  // console.log("calculate metrics output",data)



  // const ticks = isMobile ? 10 : 7

  // const weeklyData = data.map(series => ({
  //   ...series,
  //   data: series.data.filter((_, index) => index % ticks === 0),
  // }));
  const weeklyData = getFilteredSeriesData(data, isMobile)
  // console.log("weeklyData",weeklyData)

    const xScale = {
      type: 'time',
      format: '%Y-%m-%d', // Adjust based on your data's date format
      precision: 'day',
    };
  
    // Adjusted axisBottom for weekly ticks
    // Note: You may need to calculate or adjust tickValues based on your dataset
    const axisBottom = {
      orient: "bottom",
      tickSize: 0.5,
      tickPadding: 5,
      tickRotation: 45, // Rotate ticks to 45 degrees
      legend: isDashboard ? undefined : "yield",
      legendOffset: 36,
      legendPosition: "middle",
      // Assuming your data is an array of objects with a date property
      // Format your dates in tickValues or use a function to return a date for each week
      tickValues: 'every 7 days', // Or a specific array of dates for weekly intervals
      format: '%b %d', // Use a time format that suits your data
    };  

    const customTooltip = ({ point }) => {
      // Assuming the x value is a date and y value is the value you want to display
      const formattedDate = new Date(point.data.x).toLocaleDateString("default", {
        year: 'numeric', month: 'short', day: 'numeric'
      });
      const formattedValue = `${(point.data.y).toFixed(2)}%`; // Assuming you want to show percentages
  
      return (

        <div style={{ color: colors.grey[100], background: "white", padding: "10px", borderRadius: "5px", boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)" }}>
  <Box display="flex" alignItems="center">
    {/* Colored Circle on the left */}
    <div style={{ width: "10px", height: "10px", borderRadius: "50%", background: point.borderColor, marginRight: "10px" }}></div>
    
    {/* Text content on the right */}
    <div>
      <strong style={{ color: colors.primary[500] }}>Date:</strong> <span style={{ color: colors.primary[500] }}>{formattedDate}</span><br />
      <strong style={{ color: colors.primary[500] }}>Value:</strong> <span style={{ color: colors.primary[500] }}>{formattedValue}</span>
    </div>
          </Box>
        </div>
      );
    };

  return (
    // <div id={idLine}>
    <ResponsiveLine
      data={weeklyData}
      xScale={xScale}
      axisBottom={axisBottom}
      tooltip={customTooltip}
      theme={{
        axis: {
          domain: {
            line: {
              stroke: colors.grey[100],
            },
          },
          legend: {
            text: {
              fill: colors.grey[100],
            },
          },
          ticks: {
            line: {
              stroke: colors.grey[100],
              strokeWidth: 1,
            },
            text: {
              fill: colors.grey[100],
            },
          },
        },
        legends: {
          text: {
            fill: colors.grey[100],
            fontSize: isMobile ? 8 : 10,
          },
        },
        tooltip: {
          container: {
            color: colors.primary[500],
          },
        },
      }}
      colors={isDashboard ? { scheme: "dark2" } : { scheme: "dark2" }} // added
      margin={{ top: 50, right: isMobile ? 50 : 110, bottom: 100, left: 60 }}
      // xScale={{ type: "point" }}
      yScale={{
        type: "linear",
        min: "auto",
        max: "auto",
        stacked: false,
        reverse: false,
      }}
      yFormat=" >-.2f" 
      curve="catmullRom"
      axisTop={null}
      axisRight={null}
      // axisBottom={{
      //   orient: "bottom",
      //   tickSize: 0,
      //   tickPadding: 5,
      //   tickRotation: 0,
      //   legend: isDashboard ? undefined : "yield", // added
      //   legendOffset: 36,
      //   legendPosition: "middle",
      // }}
      axisLeft={{
        format: value => `${value}%`,
        orient: "left",
        tickValues: 5, // added
        tickSize: 3,
        tickPadding: 5,
        tickRotation: 0,
        legend: isDashboard ? undefined : "yield", // added
        legendOffset: -40,
        legendPosition: "middle",
      }}
      enableGridX={false}
      enableGridY={false}
      pointSize={2}
      pointColor={{ theme: "background" }}
      pointBorderWidth={2}
      pointBorderColor={{ from: "serieColor" }}
      pointLabelYOffset={-12}
      useMesh={true}
      legends={isMobile? [] : [
        {
          anchor: "bottom-right",
          direction: "column",
          justify: false,
          translateX: 100,
          translateY: 0,
          itemsSpacing: 0,
          itemDirection: "left-to-right",
          itemWidth: 80,
          itemHeight: 20,
          itemOpacity: 0.75,
          symbolSize: 8,
          symbolShape: "circle",
          symbolBorderColor: "rgba(0, 0, 0, .5)",
          effects: [
            {
              on: "hover",
              style: {
                itemBackground: "rgba(0, 0, 0, .03)",
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
    />
    // </div>

  );
};

export default LineChart;