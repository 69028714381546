import { Box, Typography, Dialog, DialogContent,CircularProgress } from "@mui/material";
import {
  DataGrid,
  GridToolbar,
  GridRowModes,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from "@mui/x-data-grid";
import { tokens } from "../../theme";
import LinearWithValueLabel from "../../components/ProgressBar";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import { useGetNotesQuery } from "../../state/api";
import { useUpdateNoteMutation } from "../../state/api";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import Button from "@mui/material/Button";
import Form from "../forms/addNote";
import { useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import { useMobile } from '../../MobileContext';
import {
  useDeleteNoteMutation,
  useGetTotalInvestmentsQuery,
} from "../../state/api";
import { useUser } from "../../UserContext";

const Notes = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useMobile()
  const { user, userData, setUserData } = useUser();
  const role = userData ? userData.rows[0].role : "nan";
  const { data, isLoading } = useGetNotesQuery(); // {    refetchOnMountOrArgChange: true,  }

  const [updateNote, { isLoadingNote, isSuccess, isError }] =
    useUpdateNoteMutation();
  const [deleteNote, { isLoadingNotes, isSuccessDelete, isErrorDelete }] =
    useDeleteNoteMutation();
  const [open, setOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const { data: InvestmentsData, isLoading: isLoadingInvestments } =
    useGetTotalInvestmentsQuery();

  // console.log(InvestmentsData)  


  useEffect(() => {
    // Ensure both datasets are loaded
    if (!isLoading && !isLoadingInvestments && data && InvestmentsData) {
      // Merge the investments data into the notes data
      const mergedRows = data.map((note) => {
        // Find the corresponding investment for this note
        const investmentForNote = InvestmentsData.find(
          (investment) => investment.noteid === note.noteid
        );

        return {
          ...note,
          // Add or override properties from the investments data as necessary
          total_invested: investmentForNote
            ? investmentForNote.total_invested
            : 0,
          max_investable_amount: investmentForNote
            ? investmentForNote.max_investable_amount
            : 0,
            note_latest_price: investmentForNote
            ? investmentForNote.notelatestprice
            : 0,

        };
      });
      // console.log(mergedRows)

      setRows(mergedRows);
    }
  }, [isLoading, isLoadingInvestments, data, InvestmentsData]); // Depend on both loading states and data

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleNoteAdded = (newNote) => {
    setRows((prevRows) => [...prevRows, newNote]);
  };

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => async () => {
    try {
      // If deleteNote is successful, filter out the deleted row
      setRows(rows.filter((row) => row.noteid !== id));
      // Assuming deleteNote makes an API call and returns a promise
      await deleteNote(id);
    } catch (error) {
      console.error("Failed to delete note", error);
      // Handle any errors, e.g., showing an error message to the user
    }
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
    const editedRow = rows.find((row) => row.noteid === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.noteid !== id));
    }
  };

  const processRowUpdate = async (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    try {
      await updateNote(updatedRow).unwrap();
    } catch (error) {
      console.error("Failed to update note", error);
    }
    setRows(
      rows.map((row) => (row.noteid === newRow.noteid ? updatedRow : row))
    );
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns = [
    // { field: "noteid", headerName: "ID", flex: 0.5 },
    // { field: "registrarId", headerName: "Registrar ID" },
    {
      field: "name",
      headerName: "Name",
      flex: 0.5,
      cellClassName: "name-column--cell",
      editable: role === "admin",
    },
    {
      field: "description",
      headerName: "Description",
      flex: 0.5,
      headerAlign: "left",
      align: "left",
      editable: role === "admin",
    },
    {
      field: "expirationdate",
      headerName: "Exp. Date",
      valueGetter: ({ value }) => value && new Date(value),
      type: "date",
      flex: 0.5,
      editable: role === "admin",
    },
    {
      field: "amount",
      headerName: "Size",
      flex: 0.5,
      editable: role === "admin",
      headerAlign: "left",
      align: "left",
      type: "number",
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return `${(params.value / 1000000).toLocaleString()} M`;
      },
    },
    {
      field: "note_latest_price",
      headerName: "Note Price",
      flex: 0.5,
      // editable: role === "admin",
      headerAlign: "left",
      align: "left",
      type: "number",
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return `${(Math.round(params.value * 1000)/1000).toLocaleString()}`;
      },
    },
    {
      field: "coupon",
      headerName: "Coupon",
      flex: 0.5,
      editable: role === "admin", // Conditionally set the editable property based on role
      headerAlign: "left",
      align: "left",
      type: "number",
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return `${params.value.toLocaleString()} %`;
      },
    },
    {
      field: "currency",
      headerName: "Currency",
      flex: 0.5,
      headerAlign: "left",
      align: "left",
      // editable: role === "admin",
    },
    {
      field: "underlying",
      headerName: "Underlying",
      flex: 0.5,
      type: "singleSelect",
      valueOptions: ["Stocks", "Bonds", "Options", "Private Credit", "Other"],
      editable: role === "admin", // Conditionally set the editable property based on role
    },
    {
      field: "barrier",
      headerName: "Barrier",
      flex: 0.5,
      editable: true,
      headerAlign: "left",
      align: "left",
      type: "number",
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return `${params.value.toLocaleString()} %`;
      },
    },
    {
      field: "isin",
      headerName: "ISIN",
      flex: 0.5,
      editable: role === "admin", // Conditionally set the editable property based on role
    },
    {
      field: "progress_percentage",
      headerName: "Status",
      sortable: true,
      flex: 0.5,
      align: "left",
      renderCell: (params) => {
        const progress =
          params.row.total_invested !== null &&
          params.row.max_investable_amount > 0
            ? (params.row.total_invested / (parseFloat(params.row.total_invested) + parseFloat(params.row.max_investable_amount))) *
              100
            : 0;
        // const { total_invested, max_investable_amount } = params.row;
        // const progress =
        //   total_invested != null && max_investable_amount > 0 && !isNaN(total_invested) && !isNaN(max_investable_amount)
        //     ? (total_invested / (total_invested + max_investable_amount)) * 100
        //     : 0;
        return (
          <>
            <LinearWithValueLabel
              color="success"
              sx={{ color: "#1bd900" }}
              value={progress}
            />
          </>
        );
      },
    },
  ];
  if (role === "admin") {
    columns.push({
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    });
  }

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Notes" />

        {role === "admin" && (
          <Box>
            <Button
              onClick={handleClickOpen}
              sx={{
                backgroundColor: colors.greenAccent[500],
                color: colors.grey[100],
                fontSize: "10px",
                fontWeight: "bold",
                padding: "10px 20px",
                mr: "20px",
              }}
            >
              <AddOutlinedIcon sx={{ mr: "10px" }} />
              Add Note
            </Button>
            <Dialog open={open} onClose={handleClose}>
              <DialogContent>
                <Form onClose={handleClose} onNoteAdded={handleNoteAdded} />
              </DialogContent>
            </Dialog>
          </Box>
        )}
      </Box>
      {data && InvestmentsData && !isLoading && !isLoadingInvestments ? (
        <Box
          m="40px 0 0 0"
          height="75vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.greenAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.greenAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.greenAccent[700],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.greenAccent[200]} !important`,
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${colors.grey[100]} !important`,
            },
          }}
        >
          {/* <DataGrid
          rows={data}
          columns={columns.map((column) => ({ ...column, editable: editRowsId === column.id }))}
          getRowId={(row) => row.noteid}
          components={{ Toolbar: GridToolbar }}
          // processRowUpdate={handleProcessRowUpdate}
          // experimentalFeatures={{ newEditingApi: true }} // Enable if using MUI X v5.0.0-beta.0 or later
        /> */}
          <DataGrid
            rows={rows}
            columns={columns}
            editMode="row"
            getRowId={(row) => row.noteid}
            rowModesModel={rowModesModel}
            components={{ Toolbar: GridToolbar }}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            style={{height: '100%',
            fontSize: isMobile ? '8px' : '10px'}}
            // slotProps={{
            //   toolbar: { setRows, setRowModesModel },
            // }}
          />
        </Box>
      ) : (
        <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="50vh"
        // width="100vw"
    >
        <CircularProgress />
    </Box>       )}
    </Box>
  );
};

export default Notes;
