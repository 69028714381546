import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';

function LinearProgressWithLabel({ value }) {
  const [displayValue, setDisplayValue] = useState(0);

  useEffect(() => {
    // Interval to animate progress
    const interval = setInterval(() => {
      setDisplayValue((prevDisplayValue) => {
        // Determine the next display value
        const nextValue = prevDisplayValue + 1;
        // Stop incrementing if next value would exceed the target value
        if (nextValue > value) {
          clearInterval(interval);
          return value; // Ensure the display value does not exceed the target value
        }
        return nextValue;
      });
    }, 20); // Adjust interval duration as needed for smooth animation

    // Clean up interval on component unmount
    return () => clearInterval(interval);
  }, [value]);

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" value={displayValue <= 100 ? displayValue : 100} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">
          {`${Math.round(value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

export default LinearProgressWithLabel;
