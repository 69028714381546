import React, { createContext, useState, useContext, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useGetUserIdQuery } from './state/api';
import { useNavigate } from 'react-router-dom';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const { user, isLoading: isAuthLoading, error: authError } = useAuth0();
  const userEmail = user?.email ?? null;
  const { data: userData, isLoading: isUserDataLoading, error: userDataError } = useGetUserIdQuery(userEmail, {
    skip: !userEmail,
  });

  // Example: State for additional user preferences
  const [userPreferences, setUserPreferences] = useState({});

  // Refresh user data function
  const refreshUserData = () => {
    // Implement logic to refresh user data here
    // This could involve re-fetching user data or resetting states
  };

  // Aggregate loading and error states for easy access
  const isLoading = isAuthLoading || isUserDataLoading;
  const error = authError || userDataError;

  useEffect(() => {
    // You might want to do something when userData is fetched or when an error occurs
  }, [userData, error]);

  return (
    <UserContext.Provider value={{ user, userData, isLoading, error, userPreferences, setUserPreferences, refreshUserData }}>
        {/* <UserProviderEffects user={user} userData={userData} userEmail={userEmail} /> */}

      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
