// import { Box, compose } from "@mui/system";
import Header  from "../../components/Header";
import { tokens } from "../../theme";
import {Box, Typography, useTheme, Select, MenuItem, FormControl, InputLabel, CircularProgress,Button, ButtonGroup } from '@mui/material';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import TrendingUpOutlinedIcon from '@mui/icons-material/TrendingUpOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import LineChart from "../../components/LineChart";
import BarChart from "../../components/BarChart";
import PieChart from "../../components/PieChart";
import StatBox from "../../components/StatBox";
import Investments from "../investments";
import {useUser} from '../../UserContext';
import { useMobile } from '../../MobileContext';
import React from 'react';
import {useGetDashboardDataQuery,useGetDashboardDataAdminQuery,useGetPerformanceQuery,useGetTotalInvestmentsQuery} from '../../state/api'
import { calculateMetrics,mergeDatasets,calculateInvestmentsPerformanceYTM,filterDataByCurrencyISIN,addTotalReturnToData,calculateInvestmentsYTM, getTotalReturn, calcLineChart, calcBarChart,calcBarChartAdmin, calcPieChart, getFilteredData, calculateWeightedPercentChange, calcLineChartAdmin, filterDataByCurrency} from "../../metricsCalculation";
import { useState,useEffect } from 'react';
import { ContactSupportOutlined } from "@mui/icons-material";



const Dashboard = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isMobile = useMobile();
    const gridTemplateColumns = "repeat(12, 1fr)";
    const gridColumnSpan = isMobile ? "span 6" : "span 3";
    const gridColumnSpanFull = isMobile ? "span 12" : "span 8"; // Use full width for certain elements that should always span full width
    const gridRowSpan = "span 2"; // Example for spanning two rows, adjust as needed
    const titleVariant = isMobile ? "h5" : "h3"; // Decrease size for mobile
    const subtitleVariant = isMobile ? "h7" : "h5";
    const { user, userData, isLoading: isLoadingUser } = useUser();
    const [selectedCurrency, setSelectedCurrency] = useState('ILS');
    const [selectedIsin, setSelectedIsin] = useState('all');
    
    // Assuming role and investorId are part of userData
    const role = userData?.rows?.[0]?.role || 'nan';
    const investorId = userData?.rows[0]?.investorid || 'nan';
    // Assuming you have a custom hook or function to fetch dashboard data
    const { data, isLoadingData, error } = useDashboardData(role, investorId);
    const currencies = data ? [...new Set(data.map(item => item.currency))] : [];
    const { data: performanceData, isLoading: isLoadingPerformance, error: errPrf } = useGetPerformanceQuery();
    const { data: totalData, isLoading: isLoadingTotals, error: errTotal } = useGetTotalInvestmentsQuery();
    console.log("totalDAta",totalData)
    console.log("performanceData",performanceData)
    const [filteredData, setFilteredData] = useState([]);
    const [filteredDataTotals, setFilteredDataTotals] =  useState([]);

    // Fetch and process admin-specific data
    const calculateTotalReturn = role === 'admin' && performanceData ? getTotalReturn(performanceData) : null;
    const mergedDs = role === 'admin' && filteredDataTotals && performanceData ? mergeDatasets(performanceData, filteredDataTotals, "noteid") : null;
    const getDataforNAV = role === 'admin' && calculateTotalReturn ? addTotalReturnToData(mergedDs, calculateTotalReturn) : null;
    // const latestData = data ? data.filter(item => item.rn === "1") : null;
    const calculatePerformanceValueLatest = data ? calculateInvestmentsYTM(filteredData) : null;
    // console.log("calculatePerformanceValueLatest",calculatePerformanceValueLatest)
    const metrics = data ? calculateMetrics(calculatePerformanceValueLatest) : null;
    
    const lineChartData = role === 'admin' ? calcLineChartAdmin(calculateTotalReturn) : calcLineChart(calculatePerformanceValueLatest);
    // const { chartData: barChartData, isinsList } = role === 'admin' ? getDataforNAV ? calcBarChartAdmin(getDataforNAV) : { chartData: null, isinsList: null } : calcBarChart(calculatePerformanceValueLatest);
    let barChartData, isinsList;

    if (role === 'admin' && getDataforNAV) {
      ({ chartData: barChartData, isinsList } = calcBarChartAdmin(getDataforNAV));
      console.log("ISINlist",isinsList)
    } else if (role === 'admin' && !getDataforNAV) {
      barChartData = null;
      isinsList = null;
    } else {
      ({ chartData: barChartData, isinsList } = calcBarChart(calculatePerformanceValueLatest));
    }
    const pieChartData = data ? calcPieChart(filteredData) : null;

    const filteredDataBar = barChartData ? getFilteredData(barChartData, selectedIsin) : null;
    // // const percentOfChange = data ?  calculateWeightedPercentChange(data.filter(item => item.rn === "1" || item.rn === "2"))  : null;
    console.log("selectedISIN",selectedIsin)
    const handleCurrencyChange = (currency) => {

        setSelectedCurrency(currency);
        // console.log("selected currency",currency)
        const filtered = filterDataByCurrency(data, currency);
        const filteredTotals = filterDataByCurrency(totalData, currency);
        setFilteredData(filtered);
        setFilteredDataTotals(filteredTotals)

    };




    function useDashboardData(userRole,investorId) {
        const adminQueryResult = useGetDashboardDataAdminQuery(investorId);
        const userQueryResult = useGetDashboardDataQuery(investorId);

        const filterByDate = (data) => {
            return data.filter(item => new Date(item.latest_performance_date) >= new Date(item.investmentdate));
        };

        if (userRole === 'admin') {
          return adminQueryResult;
        } else {
            const filteredData = filterByDate(userQueryResult.data || []);
            return {...userQueryResult, data: filteredData};
        //   return userQueryResult;
        }
      }
      
      useEffect(() => {
        if (data &&  totalData) {
          // Initially, set filtered data to all processed data
          handleCurrencyChange(selectedCurrency)
        //   const filtered = filterDataByCurrencyISIN(data, selectedCurrency, selectedIsin);
        //   const filteredTotals = filterDataByCurrencyISIN(totalData, selectedCurrency, selectedIsin);
        //   setFilteredData(filtered);
        //   setFilteredDataTotals(filteredTotals)
        //   console.log("filtered",filtered)
        //   console.log("filteredTotals", filteredTotals)
 
        }
      }, [data,totalData,selectedCurrency]);




    return (<Box m= "20px">
        <Box display="flex" justifyContent="space-between" alignItems="center" p={1}>
        <Header title = "DASHBOARD"/>
        
        {/* <Box>
            <Button 
            onClick={handleDownloadReport}
            sx={{backgroundColor: colors.greenAccent[500],color:colors.grey[100],fontSize: "14px", fontWeight: "bold", padding:"10px 20px"}}
            >
                <DownloadOutlinedIcon sx={{mr: "10px"}} />
                Download Reports
            </Button>
        </Box> */}
        
        <Box display="flex" >

        <FormControl fullWidth margin="dense" size='small'  sx = {{mr : 2}}>
                        <InputLabel id="isin-select-label">ISIN</InputLabel>
                        {isinsList ? (
                        <Select
                        labelId="isin-select-label"
                        id="isin-select"
                        value={selectedIsin}
                        label="ISIN"
                        onChange={(e) => setSelectedIsin(e.target.value)}
                        sx={{
                            height: '40px', // Adjust height to match ButtonGroup
                          }}
                        >
                        <MenuItem value="all">All</MenuItem>
                        {isinsList.map((isin) => (
                            <MenuItem key={isin} value={isin}>{isin}</MenuItem>
                        ))}
                        </Select>
                        ) : undefined
}
                        </FormControl> 
                        {/* <IconButton>
                        <DownloadOutlinedIcon
                        sx= {{fontSize : "26px", color: colors.greenAccent[500]}}/>
                        </IconButton> */}

        <ButtonGroup variant="outlined" size="small"  aria-label="Basic button group" sx={{ mr : 4,'& .MuiButtonGroup-groupedText':{maxHeight: '10px',borderColor:'#fff'},          '& .MuiButtonGroup-grouped': {
            maxHeight: '50px',
            borderColor: '#c2c2c2'
             // Adjust height to match Select
          }}}>
          {['$','ILS', '€'].map((currency) => (
            <Button
              key={currency}
              onClick={() => handleCurrencyChange(currency)}
              disabled={!currencies.includes(currency) && currency !== 'All'}
              variant={selectedCurrency === currency ? 'contained' : 'outlined'}
              sx={{
                bgcolor: selectedCurrency === currency ? colors.grey[600] : 'transparent', // Blueish color for selected
                color: selectedCurrency === currency ? 'white' : colors.grey[500],
                '&:hover': {
                  bgcolor: selectedCurrency === currency ? colors.grey[400]  : 'transparent', // Darker blueish color on hover
                }
              }}
            >
              {currency}
            </Button>
          ))}
        </ButtonGroup>




        
        </Box>

        
        
        </Box>
        {!isLoadingData && data && isinsList ? (

            <Box
            display="grid"
            gridTemplateColumns={gridTemplateColumns}
            // gridAutoRows="140px"
            gap="20px"
            id="dashboardContent"
            >
            <Box gridColumn={gridColumnSpan} backgroundColor={colors.primary[400]} display="flex" alignItems="center" justifyContent="center"   sx={{borderRadius: "1rem",
  boxShadow: "0.05rem 0.1rem 0.15rem 0.1rem rgba(0, 0, 0, .15)"}}>
                <StatBox title={`${((metrics.totalInvestmentNAV)/1000).toLocaleString()}K ${selectedCurrency}`} subtitle="Portfolio NAV" progress="0.60" increase="+14%" symbol={selectedCurrency} icon={
                <AccountBalanceOutlinedIcon sx={{color: colors.grey[100],fontSize: isMobile ? "15px" : "26px"}} 
                />
                }isPercentchange={true} percentChange={(metrics.totalGains)} />
            </Box>
            <Box gridColumn={gridColumnSpan} backgroundColor={colors.primary[400]} display="flex" alignItems="center" justifyContent="center" sx={{borderRadius: "1rem",
  boxShadow: "0.05rem 0.1rem 0.15rem 0.1rem rgba(0, 0, 0, .15)"}}>
                <StatBox title={`${((metrics.weightedAvgYTM*100)).toLocaleString()}%`} subtitle="Expected Annual Return" progress="0.60" increase="+14%" icon={
                <TrendingUpOutlinedIcon sx={{color: colors.grey[100],fontSize: isMobile ? "15px" : "26px"}} />
                }/>
            </Box> 
            <Box gridColumn={gridColumnSpan} backgroundColor={colors.primary[400]} display="flex" alignItems="center" justifyContent="center" sx={{borderRadius: "1rem",
  boxShadow: "0.05rem 0.1rem 0.15rem 0.1rem rgba(0, 0, 0, .15)"}}>
                <StatBox title={`+ ${((Math.round(metrics.totalReturn*100*1000)/1000).toFixed(3)).toLocaleString()}%`} subtitle="Total Return" progress="0.60" increase="+14%"  icon={
                <AssessmentOutlinedIcon sx={{color: colors.grey[100],fontSize: isMobile ? "15px" : "26px"}} />
                } />
                {/* isPercentchange={true} percentChange={percentOfChange} symbol="%" */}
            </Box>          
            <Box gridColumn={gridColumnSpan} backgroundColor={colors.primary[400]} display="flex" alignItems="center" justifyContent="center" sx={{borderRadius: "1rem",
  boxShadow: "0.05rem 0.1rem 0.15rem 0.1rem rgba(0, 0, 0, .15)"}}>
                <StatBox title={`${((Math.round(metrics.avgDuration*1000)/1000).toFixed(2)).toLocaleString()} Years`} subtitle="AVG Maturity" progress="0.60" increase="+14%" icon={
                <CalendarMonthOutlinedIcon sx={{color: colors.grey[100],fontSize: isMobile ? "15px" : "26px"}} />
                }/>
            </Box>
            {/* Row 2 */}
            <Box
            gridColumn={gridColumnSpanFull} 
            gridRow = {gridRowSpan}
            backgroundColor = {colors.primary[400]}
            sx={{borderRadius: "1rem",
            boxShadow: "0.05rem 0.1rem 0.15rem 0.1rem rgba(0, 0, 0, .15)"}}
            >
            <Box 
            mt = "25px"
            p = "0 30px"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            >
                <Box>
                    <Typography variant={subtitleVariant} fontWeight="600" color= {colors.grey[100]}>
                        Estimated Annual NAV
                    </Typography>
                    <Typography variant={titleVariant} fontWeight="500" color= {colors.greenAccent[500]}>
                    {`${Math.ceil((metrics.totalInvestment)*(1 + parseFloat(metrics.weightedAvgYTM))).toLocaleString()} ${selectedCurrency}`}
                    </Typography>
                    </Box>
                    {/* <Box display="flex">
                        <FormControl fullWidth margin="dense" size='small'>
                        <InputLabel id="isin-select-label">ISIN</InputLabel>
                        
                        <Select
                        labelId="isin-select-label"
                        id="isin-select"
                        value={selectedIsin}
                        label="ISIN"
                        onChange={(e) => setSelectedIsin(e.target.value)}
                        >
                        <MenuItem value="all">All</MenuItem>
                        {isinsList.map((isin) => (
                            <MenuItem key={isin} value={isin}>{isin}</MenuItem>
                        ))}
                        </Select>
                        </FormControl> */}
                        {/* <IconButton>
                        <DownloadOutlinedIcon
                        sx= {{fontSize : "26px", color: colors.greenAccent[500]}}/>
                        </IconButton> */}
                    {/* </Box> */}
                </Box>
                <Box height="250px" ml = "-20px">
                {/* <LineChart isDashboard={true} data={lineChartData}/>      */}
                <BarChart id="barChartNav" isDashboard={true} data={filteredDataBar} isinsList={isinsList} selectedIsin={selectedIsin} />            
       
                </Box> 

            </Box>
            <Box
            gridColumn = {isMobile ? gridColumnSpanFull : "span 4"}
            gridRow = {gridRowSpan}
            display="flex"
            flexDirection="column"
            sx={{borderRadius: "1rem",
            boxShadow: "0.05rem 0.1rem 0.15rem 0.1rem rgba(0, 0, 0, .15)"}}
           
            backgroundColor = {colors.primary[400]}
            >
                <Box 
            mt = "20px"
            p = "0 30px"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            
            >
            <Box>
            <Typography variant={subtitleVariant} fontWeight="600" color= {colors.grey[100]}>
                        Reports
                </Typography>
                
                </Box> 

            </Box>
            <Box flex={1} overflow="hidden" >
                <Investments isDashboard={true} dashboardData={getDataforNAV}/>            
                </Box> 


            </Box>    

            {/* Row 3 */}
 

            {/* Feex Deal Distribution        */}
            <Box
            gridColumn = {gridColumnSpanFull}
            gridRow = {gridRowSpan}
            backgroundColor = {colors.primary[400]}
            sx={{borderRadius: "1rem",
            boxShadow: "0.05rem 0.1rem 0.15rem 0.1rem rgba(0, 0, 0, .15)"}}
            >
                <Box 
            mt = "25px"
            p = "0 30px"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            >
            <Box>
            <Typography variant={subtitleVariant} fontWeight="600" color= {colors.grey[100]}>
                        Portfolio Return
                </Typography>
                
                </Box> 

            </Box>
            <Box height="300px" ml = "-15px">
            <LineChart idLine={"lineChartYield"} isDashboard={true} data={lineChartData} />            

                {/* <BarChart isDashboard={true} data={barChartData} isinsList={isinsList}/>             */}
                </Box> 


            </Box>    
            <Box
            gridColumn = {isMobile ? gridColumnSpanFull : "span 4"}
            gridRow = {gridRowSpan}
            backgroundColor = {colors.primary[400]}
            sx={{borderRadius: "1rem",
            boxShadow: "0.05rem 0.1rem 0.15rem 0.1rem rgba(0, 0, 0, .15)"}}
            >
                <Box 
            mt = "25px"
            p = "0 30px"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            >
            <Box>
            <Typography variant={subtitleVariant} fontWeight="600" color= {colors.grey[100]}>
                        Portfolio Distribution By Assets
                </Typography>
                
                </Box> 

            </Box>
            {/* <Box height="400px" ml = "-15px"> */}
            <Box height="300px" ml="-15px"  sx={{
      '& .recharts-wrapper': { // Target the chart wrapper class for Recharts, adjust if using a different library
        maxWidth: '100%', 
        height: '100%',
      },
    }}>
                <PieChart isDashboard={true} data={pieChartData}/>            
                </Box> 


            </Box>   


            </Box>
            ) : (
    //   <Typography>Loading...</Typography>
    <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    height="50vh"
    // width="100vw"
>
    <CircularProgress />
</Box>    )}            

        </Box>

    )
}

export default Dashboard; 