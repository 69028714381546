import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import { useMobile } from "../MobileContext";
import ProgressCircle from "./ProgressCircle";
import CircularProgressWithLabel from "./ProgressCircle2";
const StatBox = ({ title, subtitle, icon, isPercentchange = false, percentChange = 0, symbol = "" }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useMobile();
  const color = percentChange < 0 ? colors.redAccent[400] : colors.greenAccent[600];
  // const trend = percentChange < 0 ? "-" : "+";
  const trend = percentChange < 0 ? <RemoveOutlinedIcon sx={{fontSize: "12px"}}/> : <AddOutlinedIcon sx={{fontSize: "12px"}}/>;
  const titleVariant = isMobile ? "h9" : "h5"; // Decrease size for mobile
  const subtitleVariant = isMobile ? "h9" : "h6";
  return (

    <Box width="100%" height="100%" m="0 10px" display="flex" justifyContent="space-between" alignItems="center" p={isMobile ? "8px" : "25px"}>
    <Box display="flex" flexDirection="column" alignItems="left" >
      {icon}
      <Typography
        variant={subtitleVariant}
        sx={{ color: colors.primary[100], mt: "5px"}} // Add margin to separate icon and subtitle
      >
        {subtitle}
      </Typography>
    </Box>
    
    <Box m="0px 0px 10px 20px" >
      <Typography
        variant={titleVariant}
        fontWeight="bold"
        sx={{ color: colors.grey[100] }}
      >
        {title}
      </Typography>
      {isPercentchange && (
        <Box display="flex"  alignItems="center">
          <Box sx={{ color: color, fontWeight: "bold", ml:-0.5  }}>
            {trend} 
          </Box>
          <Typography variant={subtitleVariant} sx={{ color: color, fontWeight: "bold", ml:-0.05 }}>
            {`${Math.floor(percentChange).toLocaleString()}`} {symbol}
          </Typography>
        </Box>
      )}
    </Box>
  </Box>
  );
};

export default StatBox;