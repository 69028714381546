import { tokens } from "./theme";




export function calculateInvestmentsPerformanceYTM(investments) {
  if (!Array.isArray(investments) || investments.length === 0) {
      return {
          totalInvestment: 0,
          weightedAvgYield: 0,
      };
  }

  // const dedupedInvestments = new Map();
  // investments.forEach(investment => {
  //   dedupedInvestments.set(investment.investmentid, investment);
  // });

  const ytmData = calculateInvestmentsYTM(investments);
  let totalInvestment = 0;
  let weightedYieldTM = 0;

  ytmData.forEach(({ investmentamount, yieldToMaturity }) => {
      const amount = parseFloat(investmentamount)
      totalInvestment += amount;
      weightedYieldTM += yieldToMaturity * amount;
  });

  const weightedAvgYield = totalInvestment ? (weightedYieldTM / totalInvestment) : 0;

  return {
      totalInvestment,
      weightedAvgYield: weightedAvgYield.toFixed(3)
  };
}




export function calculateMetrics(investments) {
  if (!Array.isArray(investments) || investments.length === 0) {
    return {
      totalInvestment: 0,
      weightedAvgCoupon: 0,
      avgDuration: 0,
      weightedAvgYield: 0,
    };
  }

  const deduplicateByInvestment = (data) => {
    const deduplicatedData = data.reduce((acc, current) => {
      const existing = acc.find(item => item.investmentid === current.investmentid);
  
      if (!existing || new Date(existing.latest_performance_date) < new Date(current.latest_performance_date)) {
        // Remove the old entry if it exists
        acc = acc.filter(item => item.investmentid !== current.investmentid);
        // Add the current entry
        acc.push(current);
      }
  
      return acc;
    }, []);
  
    return deduplicatedData;
  };

  const dedupedInvestments = deduplicateByInvestment(investments);

  //   // Deduplicate investments by investmentid
  // const dedupedInvestments = new Map();
  // investments.forEach(investment => {
  //   dedupedInvestments.set(investment.investmentid, investment);
  // });
  

  // console.log("dedupedInvestments",dedupedInvestments)

  let totalInvestment = 0;
  let totalInvestmentNAV = 0;
  let totalGains = 0;
  let weightedReturnTotal = 0;
  let weightedYieldTotal = 0;
  let durationYearsTotal = 0;


  dedupedInvestments.forEach(({ investmentamount, investor_nav, expirationdate, yieldToMaturity, total_return
  }) => {

    // const daysDiffInvestedExpiration = Math.floor(getDaysDifference(investmentdate,expirationdate))
    // const exitPrice = parseFloat((coupon/100*init_note_price) + parseFloat(init_note_price))
    // const remainReturn = parseFloat((exitPrice - init_invest_price)/(init_invest_price))/daysDiffInvestedExpiration * 36500
    // const calcCoupunReduction = parseFloat((init_invest_price - init_invest_price) / init_invest_price) * 100
    // const totalReturn = parseFloat(((100*noteprice) - init_invest_price) / init_invest_price)
     
    // const amountNAV = parseFloat((investmentamount/note_size) * (nav - (calcCoupunReduction/100*nav)));
    const amount = parseFloat(investmentamount)
    const amountNAV = parseFloat(investor_nav)
    totalInvestmentNAV +=  amountNAV
    totalInvestment += amount;
    totalGains += (amountNAV - amount);
    weightedYieldTotal += (parseFloat(yieldToMaturity) * amount);
    // weightedYieldTotal += (parseFloat(yieldValue - calcCoupunReduction) * amount);
    weightedReturnTotal += (parseFloat(total_return) * amount);

    const durationYears = ((new Date(expirationdate) - new Date()) / (1000 * 60 * 60 * 24)) / 365.25; // Convert to days
    durationYearsTotal += durationYears * amount; // Weighted duration
  });

  const avgDuration = totalInvestment ? durationYearsTotal / totalInvestment : 0; // Calculate average duration weighted by investment amount
  const weightedAvgYTM = totalInvestment ? weightedYieldTotal / totalInvestment : 0;
  const totalReturn = totalInvestment ? weightedReturnTotal / totalInvestment : 0;


  return {
    totalInvestment,
    totalInvestmentNAV,
    totalGains,
    weightedAvgYTM,
    avgDuration,
    totalReturn,
  };
}

export const filterDataByCurrency = (data, currency) => {
  
  if (!Array.isArray(data) || data.length === 0) {
    return {};
  }

  if (currency === 'All') {
    return data;
  }
  return data.filter(item => item.currency === currency);
};

// Filter data by ISIN
export const filterDataByIsin = (data, isin) => {
  if (!Array.isArray(data) || data.length === 0) {
    return {};
  }
  if (isin === 'all') {
    return data;
  }

  return data.filter(item => item.isin === isin);
};

export const filterDataByCurrencyISIN = (data, currency, isin) => {
  let filteredData = filterDataByCurrency(data, currency);
  filteredData = filterDataByIsin(filteredData, isin);
  return filteredData;
};



function newtonRaphson(func, x0, tol = 0.000001, maxIter = 1000) {
  let x = x0;
  let xPrev = 0;
  let iter = 0;

  while (Math.abs(x - xPrev) > tol) {
      let fx = func(x);
      let dfx = (func(x + tol) - fx) / tol;  // Derivative approximation
      xPrev = x;
      x = x - fx / dfx;

      if (++iter > maxIter) {
          throw Error("Max iterations exceeded");
      }
  }
  return x;
}

function getYearDifference(startDate, endDate) {
  const start = new Date(startDate);
  const end = new Date(endDate);
  return (end - start) / (1000 * 60 * 60 * 24 * 365.25); // Convert milliseconds to years
}
function getDaysDifference(startDate, endDate) {
  const start = new Date(startDate);
  const end = new Date(endDate);
  return (end - start) / (1000 * 60 * 60 * 24); // Convert milliseconds to days
}


export function getTotalReturn(reports) {
  if (!reports || reports.length === 0) return {};

  // Sort reports by date in ascending order
  // reports.sort((a, b) => new Date(a.date) - new Date(b.date));

  const totalReturns = {};
  const initialPrices = {};

  reports.forEach((report) => {
    const { date, isin, adj_noteprice: adjPrice } = report;

    // Initialize the date entry if it doesn't exist
    if (!totalReturns[date]) {
      totalReturns[date] = {};
    }

    // Initialize the initial investment price for the noteId if it doesn't exist
    if (!initialPrices[isin]) {
      initialPrices[isin] = adjPrice;
    }

    const initInvestPrice = initialPrices[isin];
    const totalReturn = (adjPrice - initInvestPrice) / initInvestPrice;

    // Store the total return for the given date and noteId
    totalReturns[date][isin] = totalReturn;
  });

  return totalReturns;
}

export function addTotalReturnToData(ds, totalReturns) {
  if (!ds || !totalReturns) {
    // Return an empty array or handle the case where data is not available
    return [];
  }
  return ds.map(entry => {
    const { date, isin } = entry;
    const formattedDate = new Date(date).toISOString().slice(0, 10);

    // Get the total return for the specific date and ISIN
    const totalReturn = totalReturns[date] && totalReturns[date][isin] !== undefined
      ? totalReturns[date][isin]
      : null;

    // Add the total return to the entry
    return {
      ...entry,
      total_return: totalReturn
    };
  });
}

export function calculateInvestmentsYTM(investments) {
  if (!Array.isArray(investments) || investments.length === 0) {
    return {
    };
  }

  return investments.map(investment => {
      const { init_note_price, init_invest_price, investmentamount, coupon, investmentdate, expirationdate, noteprice, adj_noteprice, investmentid,latest_performance_date,isin } = investment;
      // const currentPrice = parseFloat(noteprice * 100);
      // const yearsToMaturity = Math.round(((new Date(expirationdate) - new Date()) / (1000 * 60 * 60 * 24)) / 365.25 * 100)/100; // Exact fractional years
      const yearsToMaturity = (getYearDifference(investmentdate,expirationdate))
      // console.log("yearsToMaturity",yearsToMaturity)
      const daysDiffInvestedExpiration = Math.floor(getDaysDifference(investmentdate,expirationdate))
      let init_note_price_norm = init_note_price < 100 ? 100 : init_note_price
      let exitPrice = parseFloat((coupon/100*init_note_price_norm) + parseFloat(init_note_price_norm)) 
      console.log("exitPrice", exitPrice)
      const remainReturn = parseFloat((exitPrice - init_invest_price)/(init_invest_price))/daysDiffInvestedExpiration * 36500
      const totalReturn = parseFloat(((adj_noteprice) - init_invest_price) / init_invest_price)
      const amount = parseFloat(investmentamount)

      // Generate cashflows and periods
        let numPayments = Math.ceil(yearsToMaturity + 1) //> 2 ? 2 : Math.ceil(yearsToMaturity + 1);
        console.log("numPayments",numPayments)
        let payment = init_note_price_norm * (coupon / 100) 
        const cashflows = new Array(numPayments).fill(payment);
        console.log("cashflows",cashflows)


        cashflows[cashflows.length - 1] += parseFloat(init_note_price_norm); // Add face value to the last payment
        console.log("cashflows_updated",cashflows)

        const periods = Array.from({length: numPayments}, (_, i) => i + yearsToMaturity);
        console.log("periods",periods)
      // console.log(cashflows,periods)
      function ytmFunction(ytmGuess) {
          return cashflows.reduce((acc, cf, idx) => acc + (cf / Math.pow(1 + ytmGuess, periods[idx])), 0) - init_invest_price;
      }

      // Initial guess for YTM as close to the coupon rate but adjusted by market price
      const initialGuess = 0.01; // Start with a more neutral guess if specific is uncertain
      const ytm = newtonRaphson(ytmFunction, initialGuess);
      console.log(ytm)

      return {
          investmentid: investmentid,
          latest_performance_date: latest_performance_date,
          expirationdate : expirationdate,
          isin: isin,
          yieldToMaturity: (ytm),
          investor_nav : (1+ totalReturn) * amount,
          total_return : totalReturn,
          investmentamount : amount,

           // Convert YTM to percentage format
      };
  });
}

export function mergeDatasets(dataset1, dataset2, key) {

  if (!Array.isArray(dataset1) || !Array.isArray(dataset2)) {
    console.warn('One or both datasets are not arrays');
    return [];
  }

  // Create a Map from dataset2 for quick lookup using the specified key
  const dataset2Map = new Map(dataset2.map(item => [item[key], item]));

  // Merge dataset1 with matched items from dataset2
  const mergedData = dataset1.map(item => {
      const dataset2Item = dataset2Map.get(item[key]);
      if (dataset2Item) {
          // Combine the two objects, dataset2 properties will overwrite dataset1 properties if they overlap
          return { ...item, ...dataset2Item };
      }
      return item; // Return unmodified item if no match is found
  });

  return mergedData;
}


  export function calculateWeightedPercentChange(investments) {

    if (!Array.isArray(investments) || investments.length === 0) {
      const percentChange = 0 
      return percentChange
    }
    // Filter investments for today and yesterday
    const todaysInvestments = investments.filter(item => item.rn === "1");
    const yesterdaysInvestments = investments.filter(item => item.rn === "2");
  
    // Calculate weighted yield for today
    let todayWeightedYieldTotal = 0;
    let todayTotalInvestment = 0;
    todaysInvestments.forEach(({ investmentamount, yield: yieldValue }) => {
      todayTotalInvestment += parseFloat(investmentamount);
      todayWeightedYieldTotal += parseFloat(investmentamount) * parseFloat(yieldValue);
    });
    const todayWeightedAvgYield = todayTotalInvestment ? todayWeightedYieldTotal / todayTotalInvestment : 0;
  
    // Calculate weighted yield for yesterday
    let yesterdayWeightedYieldTotal = 0;
    let yesterdayTotalInvestment = 0;
    yesterdaysInvestments.forEach(({ investmentamount, yield: yieldValue }) => {
      yesterdayTotalInvestment += parseFloat(investmentamount);
      yesterdayWeightedYieldTotal += parseFloat(investmentamount) * parseFloat(yieldValue);
    });
    const yesterdayWeightedAvgYield = yesterdayTotalInvestment ? yesterdayWeightedYieldTotal / yesterdayTotalInvestment : 0;
  
    // Calculate percent change
    if (yesterdayWeightedAvgYield === 0) {
      throw new Error("Yesterday's weighted average yield cannot be zero for percent change calculation.");
    }
    const change = todayWeightedAvgYield - yesterdayWeightedAvgYield;
    const percentChange = (change / yesterdayWeightedAvgYield) * 100;
  
    return percentChange;
  }
  // export function calcLineChart(investments) {
  //   if (!Array.isArray(investments) || investments.length === 0) {
  //     return [];
  //   }
  
  //   // Deduplicate investments by reportid
  //   const dedupedInvestments = new Map();
  //   investments.forEach(investment => {
  //     dedupedInvestments.set(investment.reportid, investment);
  //   });
  
  //   // Convert dedupedInvestments back to an array for sorting and processing
  //   const sortedInvestments = Array.from(dedupedInvestments.values()).sort(
  //     (a, b) => new Date(a.latest_performance_date) - new Date(b.latest_performance_date)
  //   );
  
  //   let chartDataMap = {};
  
  //   sortedInvestments.forEach(({ isin, yield: yieldValue, latest_performance_date }) => {
  //     const formattedDate = new Date(latest_performance_date).toISOString().slice(0, 10);
  //     const id = isin;
  
  //     if (!chartDataMap[id]) {
  //       chartDataMap[id] = { id, color: 'hsl(120, 57%, 40%)', data: new Map() }; // Example color in HSL format
  //     }
  
  //     // Sum yields by date before calculating cumulative yield
  //     // const currentYield = chartDataMap[id].data.get(formattedDate) || 0;
  //     chartDataMap[id].data.set(formattedDate, parseFloat(yieldValue));
  //   });
  
  //   // Now, calculate the cumulative sum for each ISIN
  //   Object.keys(chartDataMap).forEach(isin => {
  //     let cumulativeYield = 0;
  //     const dates = Array.from(chartDataMap[isin].data.keys()).sort((a, b) => new Date(a) - new Date(b)); // Ensure dates are sorted
  //     const cumulativeData = [];
  
  //     dates.forEach(date => {
  //       cumulativeYield += chartDataMap[isin].data.get(date); // Accumulate yield
  //       cumulativeData.push({ x: date, y: cumulativeYield }); // Push cumulative yield
  //     });
  
  //     chartDataMap[isin].data = cumulativeData; // Replace Map with cumulative data array
  //   });
  
  //   // Prepare the final chart data format
  //   let chartData = Object.values(chartDataMap).map(series => ({
  //     ...series,
  //     data: series.data // data is already an array of cumulative yields sorted by date
  //   }));
  
  //   return chartData;
  // }
    
  export function calcLineChartAdmin(totalReturns) {
    const formattedData = [];
    // Iterate through each date in the totalReturns object
    for (const date in totalReturns) {
      // Iterate through each noteId in the current date
      const formattedDate = new Date(date).toISOString().slice(0, 10);
      for (const isin in totalReturns[date]) {
        // Find if the noteId already exists in the formattedData array
        let noteEntry = formattedData.find(entry => entry.id === isin);
        
        // If the noteId doesn't exist, create a new entry
        if (!noteEntry) {
          noteEntry = {
            id: isin,
            color: "greenAccent[500]",
            data: []
          };
          formattedData.push(noteEntry);
        }
  
        // Push the { x, y } object to the data array of the current noteId
        noteEntry.data.push({ x: formattedDate, y: (totalReturns[date][isin])*100 });
      }
    }
  
    return formattedData;
  }



export function calcLineChart(investments) {

    if (!Array.isArray(investments) || investments.length === 0) {
      return [];
    }

    let chartDataMap = {}; // Use an object to map id to series data efficiently

    investments.forEach(({ noteid, isin, investmentamount, amount, nav,total_return ,yield: yieldValue, latest_performance_date }) => {
        const formattedDate = new Date(latest_performance_date).toISOString().slice(0, 10);
        const id = isin; // Assuming isin is the unique identifier
        // const total_return = total_return*100
        // Ensure an entry for this id exists in chartDataMap
        if (!chartDataMap[id]) {
            chartDataMap[id] = { id, color: 'greenAccent[500]', data: new Map() };
        }

        // If this date already exists, skip or update as needed
        if (!chartDataMap[id].data.has(formattedDate)) {
            // Convert Map to Array and push new date and yield
            chartDataMap[id].data.set(formattedDate, parseFloat(total_return*100));
        }
    });

    // Convert the chartDataMap to the desired array format, also converting the Map to an array of {x, y} objects
    let chartData = Object.values(chartDataMap).map(series => ({
        ...series,
        data: Array.from(series.data, ([date, total_return]) => ({ x: date, y: total_return })).sort((a, b) => new Date(a.x) - new Date(b.x))
    }))

    // console.log("Line Chart Data", chartData);
    return chartData;
}

export function getFilteredData(data, selectedIsin) {
  if (selectedIsin === 'all') {
    return data;
  }

  return data
    .filter(item => item.hasOwnProperty(selectedIsin)) // Ensure the item has the selected ISIN
    .map(item => {
      const { date } = item;
      const value = item[selectedIsin]; // Directly access since we've already filtered
      const colorKey = `${selectedIsin}Color`;
      const colorValue = item[colorKey]; // Directly access since we've already filtered

      // Construct and return a new object that includes the date, the ISIN value, and its color
      return {
        date,
        [selectedIsin]: value,
        [colorKey]: colorValue
      };
    });
}

export function calcBarChartAdmin(performanceData){
  if (!Array.isArray(performanceData) || performanceData.length === 0) {
    return { chartData: [], isinsList: [] };
  }
  const replaceNaNWithZero = (value) => (isNaN(value) ? 0 : value);
  const dataByDate = {};
  const isinsSet = new Set();
  const totalInvestmentsByIsin = {}; // Sum of investments by ISIN
  const totalAmountByIsin = {}; // Sum of amounts by ISIN
  performanceData.forEach(({ isin,total_return,total_invested, date }) => {
    total_invested = replaceNaNWithZero(parseFloat(total_invested));
    const formattedDate = new Date(date).toISOString().slice(0, 10);
    const adjustedNav = parseFloat((total_invested * (1 + total_return)).toFixed(2));

    if (!dataByDate[formattedDate]) {
      dataByDate[formattedDate] = {};
  }

  isinsSet.add(isin);
  dataByDate[formattedDate][`${isin}`] = (dataByDate[formattedDate][`${isin}`] || 0) + adjustedNav;
  dataByDate[formattedDate][`${isin}Color`] = "hsl(229, 70%, 50%)"; // Example static color, adjust as needed

})
const chartData = Object.entries(dataByDate).map(([date, isinsAndColors]) => ({
  date,
  ...isinsAndColors,
})).sort((a, b) => new Date(a.date) - new Date(b.date)); // Sorting by date in ascending order

// Convert ISINs set to array
const isinsList = Array.from(isinsSet);

// console.log("chartData", chartData);
return { chartData, isinsList };


}



export function calcBarChart(investments) {
    // console.log("calc bar",investments)
    if (!Array.isArray(investments) || investments.length === 0) {
      return { chartData: [], isinsList: [] };
    }
    const replaceNaNWithZero = (value) => (isNaN(value) ? 0 : value);
    const dataByDate = {};
    const isinsSet = new Set();
    const totalInvestmentsByIsin = {}; // Sum of investments by ISIN
    const totalAmountByIsin = {}; // Sum of amounts by ISIN




    // First, aggregate total investments and total amounts by ISIN
    investments.forEach(({ isin, investmentamount, amount }) => {
      investmentamount = replaceNaNWithZero(parseFloat(investmentamount));
      amount = replaceNaNWithZero(parseFloat(amount));

        if (!totalInvestmentsByIsin[isin]) {
            totalInvestmentsByIsin[isin] = 0;
            totalAmountByIsin[isin] = 0;
        }
        totalInvestmentsByIsin[isin] += parseFloat(investmentamount);
        totalAmountByIsin[isin] += parseFloat(amount);
    });

    // Then, calculate adjustedNav for each investment
    investments.forEach(({ isin, nav,investor_nav, latest_performance_date }) => {
        const formattedDate = new Date(latest_performance_date).toISOString().slice(0, 10);
        const sumofisin = totalInvestmentsByIsin[isin];
        const amountisin = totalAmountByIsin[isin];
        const percentOfTotal = sumofisin / amountisin;
        const adjustedNav = parseFloat((investor_nav).toFixed(2));

        if (!dataByDate[formattedDate]) {
            dataByDate[formattedDate] = {};
        }

        isinsSet.add(isin);

        // Assign NAV and Color, ensuring not to overwrite previous entries for the same date
        // This aggregates data per date, summing up adjustedNav for the same ISIN
        dataByDate[formattedDate][`${isin}`] = (dataByDate[formattedDate][`${isin}`] || 0) + adjustedNav;
        dataByDate[formattedDate][`${isin}Color`] = "hsl(229, 70%, 50%)"; // Example static color, adjust as needed
    });

    // Transform the dataByDate object into the desired array format
    const chartData = Object.entries(dataByDate).map(([date, isinsAndColors]) => ({
        date,
        ...isinsAndColors,
    })).sort((a, b) => new Date(a.date) - new Date(b.date)); // Sorting by date in ascending order

    // Convert ISINs set to array
    const isinsList = Array.from(isinsSet);

    // console.log("chartData", chartData);
    return { chartData, isinsList };
}

      
export   function calcPieChart(investments) {

        if (!Array.isArray(investments) || investments.length === 0) {
          return [];
        }
        const underlyingTotals = {};
        let totalInvestment = 0;
      
        investments.forEach(({ underlying, investmentamount }) => {
          const amount = parseFloat(investmentamount);
          if (underlyingTotals[underlying]) {
            underlyingTotals[underlying] += amount;
          } else {
            underlyingTotals[underlying] = amount;
          }
          totalInvestment += amount;
        });
      
        const pieChartData = Object.entries(underlyingTotals).map(([underlying, amount]) => ({
          id: underlying,
          label: underlying,
          value: amount / totalInvestment,
          color: "hsl(104, 70%, 50%)" // You might want to dynamically assign colors
        }));
      
        return pieChartData;
      }
        
    
    // Note: This function assumes 'amount' is the total note amount for the investment, 
    // and 'nav' adjustments are calculated per ISIN.
      
  
  