import React from 'react'
import { Box, Typography, TextField, MenuItem} from "@mui/material";
import { Field, Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../../components/Header";
import InputAdornment from '@mui/material/InputAdornment';
import { useCreateNewRequestMutation } from "../../../state/api";
import Alert from '@mui/material/Alert';
import { LoadingButton } from '@mui/lab';
import { useState, useMemo } from "react";
import { useGetNotesQuery,useGetTotalInvestmentsQuery } from "../../../state/api";
import {useUser} from '../../../UserContext';



const AddRequestPage = ({onRequestAdded }) => {

    const isNonMobile = useMediaQuery("(min-width:600px)");
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState(false);
    const {user,userData,setUserData} = useUser();
    // console.log("userData ",userData)
    const name = userData ? userData.rows[0].name :  'nan';
    const investorId = userData ? userData.rows[0].investorid :  'nan';
    const { data: notesData, isLoading: isLoadingNotes } = useGetNotesQuery();
    // const { data: investorsData, isLoading: isLoadingInvestors } = useGetInvestorsQuery();
    const { data: InvestmentsData, isLoading: isLoadingInvestments } = useGetTotalInvestmentsQuery();
    const [createNewRequest, { isLoading, isSuccess, isError }] = useCreateNewRequestMutation();



  
    const handleFormSubmit = async (values, { resetForm }) => {
      console.log("Request to allocate", values);
      setLoading(true);
      
      try {
          const newRequest = await createNewRequest(values).unwrap();
        //   console.log("new Investment: " , newInvestment)
          console.log("new Request: " , values)

          console.log('Request uploaded successfully');
          setAlert({ show: true, message: 'Request was allocated successfully.', severity: 'success' });
          setLoading(false);
          onRequestAdded(newRequest.data)
          resetForm();
          
          // Handle success, e.g., showing a success message or redirecting
          } catch (error) {
              console.error('Error submitting the form:', error);
              setLoading(false);
              setAlert({ show: true, message: 'Error check required fields', severity: 'error' });
          // Handle error, e.g., showing an error message
          }
      };    
      const checkoutSchema = yup.object().shape({
        investorid: yup.string().required('Investor is required'),
        noteid: yup.string().required('Note is required'),
        investmentdate: yup.date().required("invalid date"),
        investmentamount:   yup.number()
        .required('Investment amount is required')
        .positive('Investment amount must be positive')
        .test(
          'maxAmount',
          'Investment exceeds the maximum allowed for this note',
          function(value) {
            console.log("noteid: ", this.parent.noteid);
            console.log("notes Data", notesData)
            const note = notesData.find(n => n.noteid.toString() === this.parent.noteid.toString());
            console.log("note: ",note)
            const maxInvestment = getMaxInvestableAmount(note.noteid)
            console.log(`Value: ${value}, MaxInvestment: ${maxInvestment}`);
            return note ? value <= maxInvestment: false;
        }
        ),
      });
      const initialValues = {
        investorid: investorId,
        noteid: '',
        investmentdate: new Date().toISOString().slice(0, 10),
        investmentamount: '',

      };  

      const getMaxInvestableAmount = (noteid) => {
        const investment = InvestmentsData.find(investment => investment.noteid === noteid);
        return investment ? investment.max_investable_amount : 0;
      };

    return (
      <Box m="20px">
        <Header title="Request" subtitle="Request" />
        {(notesData && InvestmentsData) && (!isLoadingNotes && !isLoadingInvestments) ? (   

        <Formik
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          validationSchema= {checkoutSchema}
          // Ensure to include validation context if needed during validation calls
        //   validate={(values) => {
        //     const schema = checkoutSchema(notesData, InvestmentsData);
        //     return schema.validate(values, { context: { notesData, InvestmentsData } });
        //   }} //{checkoutSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                }}
              >

            <TextField 
            select 
            name="investorid"
            variant="filled"
            type="text"
            label="Investor"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.investorid}
            error={!!touched.investorid && !!errors.investorid}
            helperText={touched.investorid && errors.investorid}
            sx={{ gridColumn: "span 2" }}
            fullWidth>
            <MenuItem value="">Select an Investor</MenuItem>
              <MenuItem key={investorId} value={investorId}>
                {name}
              </MenuItem>
            </TextField>
            <TextField 
            select
            name="noteid"             
            variant="filled"
            type="text"
            label="Note"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.noteid}
            error={!!touched.noteid && !!errors.noteid}
            helperText={touched.noteid && errors.noteid}
            sx={{ gridColumn: "span 2" }}
            fullWidth>
            <MenuItem value="">Select a Note</MenuItem>
            {notesData.map(note => (
              <MenuItem key={note.noteid} value={note.noteid}>                
                {note.name} (Max: ${getMaxInvestableAmount(note.noteid)})
              </MenuItem>

            ))}
          </TextField>

          <TextField 
          type="number" 
          name="investmentamount" 
          variant="filled"
          label="Investment Amount"
          onBlur={handleBlur}
          onChange={handleChange}
          InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }} 
          value={values.investmentamount}
          error={!!touched.investmentamount && !!errors.investmentamount}
          helperText={touched.investmentamount && errors.investmentamount}
          sx={{ gridColumn: "span 2" }}
          fullWidth/>    

            <TextField
                fullWidth
                id="standard-start-adornment"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                type="date"
                label="Investment Date"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.investmentdate}
                name="investmentdate"
                error={!!touched.investmentdate && !!errors.investmentdate}
                helperText={touched.investmentdate && errors.investmentdate}
                sx={{ gridColumn: "span 2" }}
              />  
  
              </Box>
              <Box display="flex" justifyContent="end" mt="20px">
  
              <LoadingButton
          type="submit"        
          loading={loading}
          color="secondary"
          variant="contained"
        >
          Submit Request
        </LoadingButton>
        {alert.show && <Alert severity={alert.severity}>{alert.message}</Alert>}
              </Box>
            </form>
          )}
        </Formik>
        ) : (
        <Typography>Loading...</Typography>
        )}
      </Box>
    );
  };
  
  



export default AddRequestPage;
