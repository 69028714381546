import React from 'react'
import { Box, Select, TextField, MenuItem} from "@mui/material";
import { Field, FieldArray, Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../../components/Header";
import InputAdornment from '@mui/material/InputAdornment';
import { useCreateNewUserMutation } from "../../../state/api";
import Alert from '@mui/material/Alert';
import { LoadingButton } from '@mui/lab';
import { useState } from "react";

const AddUserPage = ({onUserAdded }) => {

    const isNonMobile = useMediaQuery("(min-width:600px)");
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState(false);
    const [createNewUser, { isLoading, isSuccess, isError }] = useCreateNewUserMutation();

  
    const handleFormSubmit = async (values, { resetForm }) => {
      console.log(values);
      setLoading(true);
  
      try {
          const newUser = await createNewUser(values).unwrap();
          console.log("new User: " , newUser)
          console.log("new User Data",newUser.data )
          console.log('User uploaded successfully');
          setAlert({ show: true, message: 'User was created successfully.', severity: 'success' });
          setLoading(false);
          onUserAdded(newUser.data);
          resetForm();
          
          // Handle success, e.g., showing a success message or redirecting
          } catch (error) {
              console.error('Error submitting the form:', error);
              setLoading(false);
              setAlert({ show: true, message: 'Error check required fields', severity: 'error' });
          // Handle error, e.g., showing an error message
          }
      };    
  
    return (
      <Box m="20px">
        <Header title="CREATE USER" subtitle="Create User" />
  
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          validationSchema={checkoutSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                }}
              >
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.name}
                  name="name"
                  error={!!touched.name && !!errors.name}
                  helperText={touched.name && errors.name}
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="email"
                  label="Email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                  name="email"
                  error={!!touched.email && !!errors.email}
                  helperText={touched.email && errors.email}
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="id"
                  label="ID"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.id}
                  name="id"
                  error={!!touched.id && !!errors.id}
                  helperText={touched.id && errors.id}
                  sx={{ gridColumn: "span 2" }}
                />

                <TextField
                  fullWidth
                  variant="filled"
                  select                  
                  label="Investment Profile"    
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.investmentprofile}
                  name="investmentprofile"
                  error={!!touched.investmentprofile && !!errors.investmentprofile}
                  helperText={touched.investmentprofile && errors.investmentprofile}
                  sx={{ gridColumn: "span 2" }}
            >
               <MenuItem key={""} value={""}>
                  No Selected // Or Empty
                </MenuItem>
                <MenuItem key="1" value="preferred">
                  Preferred
                </MenuItem>
                <MenuItem key="2" value="products">
                  Products
                </MenuItem>

            </TextField>

                
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="IBAN"
                //   InputProps={{
                //       startAdornment: <InputAdornment position="start">%</InputAdornment>,
                //     }}                         
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.iban}
                  name="iban"
                  error={!!touched.iban && !!errors.iban}
                  helperText={touched.iban && errors.iban}
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Bank Name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.bankname}
                  name="bankname"
                  error={!!touched.bankname && !!errors.bankname}
                  helperText={touched.bankname && errors.bankname}
                  sx={{ gridColumn: "span 2" }}
                />
  
              <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Address"
                //   InputProps={{
                //       startAdornment: <InputAdornment position="start">%</InputAdornment>,
                //     }}                     
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.barrier}
                  name="address"
                  error={!!touched.address && !!errors.address}
                  helperText={touched.address && errors.address}
                  sx={{ gridColumn: "span 2" }}
                /> 
              <TextField
                  fullWidth
                  variant="filled"
                  type="tel"
                  placeholder='(code)-xx-xxx-xxxx'
                  validation="matches:/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/"
                  label="Phone #"
                //   InputProps={{
                //       startAdornment: <InputAdornment position="start">%</InputAdornment>,
                //     }}                     
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.phonenumber}
                  name="phonenumber"
                  error={!!touched.phonenumber && !!errors.phonenumber}
                  helperText={touched.phonenumber && errors.phonenumber}
                  sx={{ gridColumn: "span 2" }}
                /> 
                <TextField
                  fullWidth
                  variant="filled"
                  select                  
                  label="Role"    
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.role}
                  name="role"
                  error={!!touched.role && !!errors.role}
                  helperText={touched.role && errors.role}
                  sx={{ gridColumn: "span 2" }}
            >
               {/* <MenuItem key={""} value={""}>
                  No Selected // Or Empty
                </MenuItem> */}
                <MenuItem key="1" value="admin">
                  Admin
                </MenuItem>
                <MenuItem key="2" value="investor">
                  Investor
                </MenuItem>
</TextField>              
  
              </Box>
              <Box display="flex" justifyContent="end" mt="20px">
  
              <LoadingButton
          type="submit"        
          loading={loading}
          color="secondary"
          variant="contained"
        >
          Create User
        </LoadingButton>
        {alert.show && <Alert severity={alert.severity}>{alert.message}</Alert>}
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    );
  };
  
  // const phoneRegExp =
  //   /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;
  
  const checkoutSchema = yup.object().shape({
    name: yup.string().required("required"),
    email: yup.string().required("required"),
    investmentprofile: yup.string().optional("optional"),
    id: yup.number().required("required"),
    iban: yup.number().optional("optional"),
    bankname: yup.string().optional("optional"),
    address: yup.string().optional("optional"),
    phonenumber: yup.string().required("required"),
    role: yup.string().required("required"),
  });
  const initialValues = {
    name: "",
    email: "",
    investmentprofile: null,
    id: "",
    iban: "",
    bankname: "",
    address: "",
    phonenumber: "",
    role: "investor"
  };


export default AddUserPage;
