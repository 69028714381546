import React, { useState } from 'react';
import { Box, Avatar, Menu, MenuItem, IconButton, Dialog, CircularProgress } from '@mui/material';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

const UserAvatar = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { user, logout, isAuthenticated } = useAuth0();
  const [loading, setLoading] = useState(false); // Use Clerk's useAuth for signOut
  const navigate = useNavigate();

  const handleAvatarClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const toggleLogout = async () => {
    setAnchorEl(null);
    setLoading(true);
    try {
      await logout({
        logoutParams: {
          returnTo: `${window.location.origin}/login`,
        },
      });
      // console.log(window.location.origin)
      // logout({
      // returnTo: `${window.location.origin}/login` });
      // navigate('/login', { replace: true });
    } catch (error) {
      console.error('Logout failed', error);
    } 
    // finally {
    //   setLoading(false); // Hide loading dialog
    // }
    // logout({
    //   logoutParams: {
    //     returnTo: navigate('/login'),
    //   },
    // }); 
  };

  // Display avatar only if user is signed in
  if (!isAuthenticated) return null;

  return (
    <Box display="flex" alignItems="center" marginRight={2} position="relative">
      <IconButton onClick={handleAvatarClick}>
        <Avatar
          src={user?.picture || ''} // Adjust according to your user object structure
          alt={user?.given_name || 'User'} // Adjust according to your user object structure
        />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem onClick={toggleLogout} style={{ display: 'flex', alignItems: 'center' }}>
          <LogoutOutlinedIcon sx={{ marginRight: 1 }} />
          Logout
        </MenuItem>
      </Menu>
      <Dialog open={loading}>
        <CircularProgress />
      </Dialog>
    </Box>
  );
};

export default UserAvatar;
