import {Box, Icon, IconButton, useTheme} from "@mui/material";
import { useContext } from "react";
import { ColorModeContext, tokens } from "../../theme";
import InputBase from "@mui/material";
import LightModeOutlinedIocon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIocon from "@mui/icons-material/DarkModeOutlined";
import Notifications from "@mui/icons-material/Notifications";
import SettingsOutlinedIocon from "@mui/icons-material/SettingsOutlined";
import PersonOutlinedIocon from "@mui/icons-material/PersonOutlined";
import SearchIcon from "@mui/icons-material/Search";
import UserAvatar from "./UserAvatar";



const Topbar = () => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode);
    const colorMode = useContext(ColorModeContext);

    return (
    <Box display="flex" justifyContent="space-between" p = {2}>
    <Box 
    display="flex"
    backgroundColor = {colors.primary[400]}
    borderRadius="3px">

    </Box>
    <Box display="flex" marginRight={2}>
        <IconButton onClick={colorMode.toggleColorMode}>
            {theme.palette.mode === "dark" ? (
                <DarkModeOutlinedIocon/>
            ):(
                <LightModeOutlinedIocon/>
            )
            }
        </IconButton>
        <IconButton>
        <Notifications/>
        </IconButton>
        <IconButton>
        <SettingsOutlinedIocon/>    
        </IconButton>
        <UserAvatar/>
        
    </Box>

    </Box>
    ) 
}

export default Topbar; 