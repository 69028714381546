import * as React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { LocalizationProvider, DatePicker as MuiDatePicker } from '@mui/x-date-pickers';
import { TextField } from '@mui/material';

export default function DatePickerComponent({value,onSelectDate}) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DatePicker']}>
        <MuiDatePicker  label="Close Date" margin= "top"
          value={value}
          onChange={(date) => onSelectDate(date)}
          renderInput={(params) => <TextField {...params} />}
        />

        {/* </Box> */}
      </DemoContainer>
    </LocalizationProvider>
  );
}