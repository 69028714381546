import { Box, Link, Typography, useTheme } from "@mui/material";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';

const Footer = () => {
    const theme = useTheme();
    const colors = theme.palette;

    return (
        <Box
            display="flex"
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
            p={2}
            backgroundColor={colors.primary[400]}
            // borderTop={`1px solid ${colors.grey[300]}`}
        >
            <Typography variant="body2" color={colors.grey[500]} mb={1}>
                &copy; {new Date().getFullYear()} Goch Capital LLC. All Rights Reserved.
            </Typography>
            <Box alignContent="center" justifyContent="center" alignItems="center" mb={1}>
                <Link href="https://www.gochcapital.com/privacy-policy" color={colors.grey[500]}  variant="body2" mr={2} target="_blank" rel="noopener noreferrer">
                    Privacy Policy
                </Link>
                <Link href="https://www.gochcapital.com/terms-of-service" color={colors.grey[500]} variant="body2" target="_blank" rel="noopener noreferrer">
                    Terms of Service

                </Link>
            </Box>

            <Box display="flex" >
                <Link href="https://www.linkedin.com/company/goch-capital/"  color={colors.grey[500]} mr={1} target="_blank" rel="noopener noreferrer">
                    <LinkedInIcon />
                </Link>
                <Link href="https://www.facebook.com/profile.php?id=61554873463900"  color={colors.grey[500]} target="_blank" rel="noopener noreferrer">
                    <FacebookIcon />
                </Link>
            </Box>
        </Box>
    );
};

export default Footer;
