import React, { useEffect,useState } from "react";
import { useNavigate, Routes, Route, Navigate } from "react-router-dom";
import { CssBaseline, ThemeProvider, Snackbar } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import { useAuth0 } from '@auth0/auth0-react';
import { QueryClientProvider } from "react-query";
import { queryClient } from "./query_client";
import { useUser } from "./UserContext";
import Topbar from "./pages/global/Topbar";
import Footer from "./pages/global/Footer";
import Sidebar from "./pages/global/Sidebar";
import LoginPage from "./pages/global/LoginPage";
import Dashboard from "./pages/dashboard";
// import Report from "./pages/reports";

import Dealroom from "./pages/dealroom";
import Notes from "./pages/notes";
import Requests from "./pages/requests";
import Users from "./pages/users";
import AddInvestmentPage from "./pages/investments";
import CircularProgress from '@mui/material/CircularProgress';
 
// ProtectedRoute component remains unchanged
const ProtectedRoute = ({ children, allowedRoles }) => {
  const { userData } = useUser();
  const role = userData ? userData.rows[0].role : "nan";
 

  if (allowedRoles && !allowedRoles.includes(role)) {
    return <Navigate to="/" replace />;
  }

  return children;
};

function App() {
  const [theme, colorMode] = useMode();
  const { user,userData,isLoading,error } = useUser();
  const {isAuthenticated} = useAuth0()
  const navigate = useNavigate();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const isUserData = !userData || userData.rows==0
  const [initialRedirectDone, setInitialRedirectDone] = useState(false);

  useEffect(() => {
    if (!isLoading) {
      if (!isAuthenticated) {
        navigate("/login");
      } else if (isAuthenticated && (!userData || userData.rows.length === 0)) {
        // Assuming userData exists and userData.rows > 0 means the user is authorized
        setOpenSnackbar(true);
        navigate("/login", { replace: true });
      } else if (isAuthenticated && userData && userData.rows.length > 0 && !initialRedirectDone) {
        setOpenSnackbar(false);
        navigate("/", { replace: true });
        setInitialRedirectDone(true); 

      }
    }
  }, [isLoading, isAuthenticated, userData, navigate]);


  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };
 
  if (isLoading) {
    // Show loading indicator while authentication state is being restored
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </div>
    );
  }

  return (
      <QueryClientProvider client={queryClient}>
        <ColorModeContext.Provider value={colorMode}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <div className="app" style={{ display: 'flex', height: '100vh', overflow: 'auto' }}>
            {isAuthenticated && userData && userData.rows.length > 0 ? (<>
              {<Sidebar/>}
              <main className="content" >
                {<Topbar />}
                <Routes>
                  <Route path="/login" element={<LoginPage />} />
                  <Route path="/" element={<Dashboard />} />
                  <Route path="/dealroom" element={<Dealroom />} />
                  <Route path="/notes" element={<Notes />} />
                  <Route path="/requests" element={<Requests />} />
                  <Route path="/users" element={<ProtectedRoute allowedRoles={["admin"]}><Users /></ProtectedRoute>} />
                  <Route path="/investments" element={<AddInvestmentPage />} />
                  {/* <Route path="/report" element={<Report />} /> */}

                  {/* <Route path="/form" element={<Form />} /> */}
                  {/* ... other routes ... */}
                </Routes>
                {<Footer />}
              </main>
              </>
              ): (
                <Routes>
                  <Route path="/login" element={<LoginPage openSnackbar={openSnackbar} onCloseAlert={handleCloseSnackbar} />} />
                  <Route path="*" element={<Navigate to="/login" replace />} />
                </Routes>
              )}

            </div>
          </ThemeProvider>
        </ColorModeContext.Provider>
      </QueryClientProvider>
  );
}

export default App;
