import React, { useState, useEffect } from 'react';
import { tokens } from "../theme";
import { Box, useTheme, Typography, CircularProgress } from "@mui/material";
import PropTypes from 'prop-types';

function CircularProgressWithLabel({ value }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= value ? value : prevProgress + 1));
    }, 5);

    return () => {
      clearInterval(timer);
    };
  }, [value]);  

  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" value={progress} style={{'color': colors.grey[100]}}/>
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div" color={colors.grey[100]}>
          {`${Math.round(progress)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

export default CircularProgressWithLabel;

