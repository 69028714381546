import { useTheme,Box} from '@mui/material';
import { ResponsiveBar } from '@nivo/bar'
import { tokens } from "../theme";
import { ContactSupportOutlined, Dashboard } from '@mui/icons-material';
import { useMobile } from '../MobileContext';
// function calculateTickValues(data, key) {
//     const numberOfTicks = 6; // Desired number of ticks
  
//     // If the length of data is less than the desired number of ticks, return all unique tick values directly from the data
//     if (data.length < numberOfTicks) {
//         return [...new Set(data.map(item => parseFloat(item[key[0]])))].sort((a, b) => a - b);
//     }
//     console.log("key ",key)
//     // Find the minimum and maximum values from the data
//     const minValue = Math.min(...data.map(item => parseFloat(item[key[0]])));
//     const maxValue = Math.max(...data.map(item => parseFloat(item[key[0]])));
//     console.log("calculateTickValues_max",maxValue)
//     console.log("calculateTickValues_min",minValue)

//     // Calculate the difference and step
//     const range = maxValue - minValue;
//     let step = range / (numberOfTicks - 1);
  
//     // Generate tick values starting at minValue and incrementing by step
//     const tickValues = Array.from({ length: numberOfTicks }, (_, i) => minValue + (i * step));
  
//     // Round tick values to a reasonable precision
//     return tickValues.map(value => Math.round(value * 100) / 100);
//   }
function calculateTickValues(data, key) {
    const numberOfTicks = 6; // Desired number of ticks
  
    // If the length of data is less than the desired number of ticks, return all unique tick values directly from the data
    if (data.length < numberOfTicks) {
        const uniqueTickValues = [...new Set(data.map(item => parseFloat(item[key[0]])))].sort((a, b) => a - b);
        const minValue = uniqueTickValues[0];
        const maxValue = uniqueTickValues[uniqueTickValues.length - 1];
        return { tickValues: uniqueTickValues, minValue, maxValue };
    }
  
    // Find the minimum and maximum values from the data
    // const minValue = Math.min(...data.map(item => parseFloat(item[key[0]])));
    // const maxValue = Math.max(...data.map(item => parseFloat(item[key[0]])));
    const minValue = Math.min(...data.map(item => item[key[0]] !== undefined ? parseFloat(item[key[0]]) : Infinity));
    const maxValue = Math.max(...data.map(item => item[key[0]] !== undefined ? parseFloat(item[key[0]]) : 0));

  
    // console.log("calculateTickValues_max", maxValue);
    // console.log("calculateTickValues_min", minValue);

    // Calculate the difference and step
    const range = maxValue - minValue;
    let step = range / (numberOfTicks - 1);
  
    // Generate tick values starting at minValue and incrementing by step
    const tickValues = Array.from({ length: numberOfTicks }, (_, i) => minValue + (i * step));
  
    // Round tick values to a reasonable precision and return them along with min and max values
    return {
        tickValues: tickValues.map(value => Math.round(value * 100) / 100),
        minValue,
        maxValue
    };
}



  function getFilteredData(data, isMobile) {
    // Define the number of ticks based on the device type
    const ticks = isMobile ? 8 : 4;

    // If the length of data is less than the number of ticks, return all data points
    if (data.length <= ticks) {
        return data;
    }

    // Otherwise, filter data to include every 'ticks'-th element
    return data.filter((_, i) => i % ticks === 0);
}
  

const BarChart = ( {id,isDashboard = false, data,isinsList,selectedIsin} ) => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode)
    // console.log("selectedISINBar",selectedIsin)
    const adjustedIsinsList = selectedIsin === "all" ? isinsList : [selectedIsin];
    // console.log("adjustedIsinsList",adjustedIsinsList)
    const formatValue = data ? (value) => `${(value / 1000).toFixed(1)}k` : undefined;
    const dates = data.map(d => d.date);
    const isMobile = useMobile()
    // const ticks = isMobile ? 8 : 4
    // const weeklyData = data.filter((_, i) => i % ticks === 0);
    // console.log("BarChart Data",data)
    const weeklyData = data ? getFilteredData(data, isMobile) : undefined;
    // console.log("weeklyData",weeklyData)
    // const {tickValues,minVal,maxVal} = data ? calculateTickValues(weeklyData, adjustedIsinsList) : undefined;
    const { tickValues, minValue: minVal, maxValue: maxVal } = data ? calculateTickValues(weeklyData, adjustedIsinsList) || {} : {};
    // console.log("tickValues",tickValues)
    // let minVal = tickValues.length > 0 ? tickValues[0] : null; // Last element or null if array is empty
    // if (minVal === null || minVal > 0) {
    //     minVal = 0; // Cap at 100k if it doesn't exist or exceeds 100k
    // }
    // const minVal = tickValues[0]; // First element
    // const maxVal = tickValues[tickValues.length - 1]; // Last element
    // let maxVal = tickValues.length > 0 ? tickValues[tickValues.length - 1] : null; // Last element or null if array is empty
    // if (maxVal === null || maxVal > 100000) {
    //     maxVal = 100000; // Cap at 100k if it doesn't exist or exceeds 100k
    // }
    // console.log("maxVal", maxVal)
        const CustomBarTooltip = ({ id, value, color, indexValue }) => {
        const formattedDate = new Date(indexValue).toLocaleDateString("default", {
            year: 'numeric', month: 'short', day: 'numeric'
          });
          return(
        <div style={{ color: colors.grey[100], background: "white", padding: '6px 12px', borderRadius: '4px' }}>
              <Box display="flex" alignItems="center">
        {/* Colored Circle on the left */}
            <div style={{ width: "10px", height: "10px", borderRadius: "50%", background: color, marginRight: "10px" }}></div>
    
            <div>
            <strong style={{color: colors.primary[500]}}>Date:</strong> <span style={{color: colors.primary[500]}}>{formattedDate}</span> <br/> <strong style={{color: colors.primary[500]}}>Value:</strong> <span style={{color: colors.primary[500]}}>{Math.round(value).toLocaleString()}</span>
            </div>
            </Box>
        </div>
        )
    };

    return (

        <>
        <ResponsiveBar
        id={id}
        data={weeklyData}
        tooltip={({ id, value, color, indexValue }) => (
            <CustomBarTooltip id={id} value={value} color={color} indexValue={indexValue} />
        )}
        valueScale={{ 
            type: 'linear',
            min: minVal,
            max: maxVal ,
            clamp: true
          }}
        theme ={{
            axis: {
                domain: {
                    line: {
                       stroke:colors.grey[100] 
                    }
                },
                legend: {
                    text: {
                        fill:colors.grey[100],
                        fontSize: isMobile ? 8 : 10,
                    }
                },
                ticks: {
                    line: {
                        stroke:colors.grey[100],
                        strokeWidth: 1
                        
                    },
                    text: {
                        fill: colors.grey[100],
                        fontSize: isMobile ? 8 : 10,

                    }
                }
            },
            legends: {
                text: {
                    fill:colors.grey[100],
                    fontSize: isMobile ? 8 : 10,
                },
                legendPosition: {
                    position:"bottom-right"
                },
                
                
            }
        }}
        keys={adjustedIsinsList}
        indexBy="date"
        margin={{ top: 20, right: isMobile ? 30 : 100, bottom: 70, left:  70 }}
        padding={0.3}
        // valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        colors={{ scheme: 'dark2' }}
        defs={[
            {
                id: 'dots',
                type: 'patternDots',
                background: 'inherit',
                color: '#38bcb2',
                size: 4,
                padding: 1,
                stagger: true
            },
            {
                id: 'lines',
                type: 'patternLines',
                background: 'inherit',
                color: '#eed312',
                rotation: -45,
                lineWidth: 6,
                spacing: 10
            }
        ]}
        fill={[
            {
                match: {
                    id: 'fries'
                },
                id: 'dots'
            },
            {
                match: {
                    id: 'sandwich'
                },
                id: 'lines'
            }
        ]}
        borderColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    1.6
                ]
            ]
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
            tickSize: isMobile ? 1 : 2,
            tickPadding: 5,
            tickRotation: isMobile ? 45 : 45, // Rotate ticks to 45 degrees
            legend: isDashboard ? undefined : 'Date', // Assuming 'date' is the index
            legendPosition: 'middle',
            legendOffset: 32,
            format: value => `${new Date(value).toLocaleDateString("default", {
                year: 'numeric', month: 'short', day: 'numeric'
              })}`, // Optionally format tick value
            // tickValues: weeklyTickValues, // Use the calculated weekly tick values
          }}
          axisLeft={{
            // ... other axisLeft properties ...
            tickPadding: 5,
            tickRotation: 0,
            legend: isDashboard ? undefined : 'Value', // Assuming you want to display values
            legendPosition: 'middle',
            legendOffset: -40,
            format: formatValue,
            tickValues: tickValues
            
          }}
        enableLabel={false}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',   
                    1.6
                ]
            ]
        }}
        legends={ isMobile ? [] : [
            {
                dataFrom: 'keys',
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 120,
                translateY: 0,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: 'left-to-right',
                itemOpacity: 0.85,
                symbolSize: 8,
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemOpacity: 1
                        }
                    }
                ]
            }
        ]}
        role="application"
        ariaLabel="Nivo bar chart demo"
        barAriaLabel={e=>e.id+": "+e.formattedValue+" in country: "+e.indexValue}
    />
    </>

    );
};

export default BarChart