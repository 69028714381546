import React from 'react';
// import { GoogleLogin,GoogleOAuthProvider } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import {
    Box,
    Typography,
    Button,
    useTheme,
    IconButton,
    Alert,
    Snackbar
  } from "@mui/material";
import Avatar from '@mui/material/Avatar';
import LoginIcon from '@mui/icons-material/Login';
import { useAuth0 } from '@auth0/auth0-react';
import { tokens } from "../../theme";


const LoginPage = ({ openSnackbar, onCloseAlert }) => {
    const { loginWithPopup,isAuthenticated,user } = useAuth0();
    const navigate = useNavigate();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);


    const handleLogin = () => {
      onCloseAlert(); // Call this to clear the snackbar before attempting login
      loginWithPopup().catch(console.error);
    };

    return (
<Box
  sx={{
    background: 'linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(37,37,96,1) 35%, rgba(5,154,124,1) 100%)',
    backgroundColor: 'linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(37,37,96,1) 35%, rgba(5,154,124,1) 100%)', // Apply to the outermost container
    height: '100vh', // Ensure it covers the full viewport height
    width: '100vw', // Ensure it covers the full viewport width
    display: 'flex', // Make this a flex container
    flexDirection: 'column', // Stack children vertically
    alignItems: 'center', // Center children horizontally
    justifyContent: 'center', // Center children vertically
  }}
>
      <Box
      sx={{
        // Apply the dark background color to the app or its root instead
        backgroundColor: '#ffffff',
        width: '100%', // Use 100% width to stretch to the edge of the parent container
        height: '60%',       
        maxWidth: '400px', // Limit the maximum width so it doesn't stretch too far on larger screens
        margin: '0 auto', // Center this box horizontally in the parent
        textAlign: 'center', // This will center-align text for all child components
        padding: '30px',
        borderRadius: '8px',
        overflow: 'hidden',
        boxShadow: '0 2px 10px -3px #333',
        justifyContent: 'flex-start', // Align items to the top
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center', // Align items in the center for this box as well
      }}
    >
      <Snackbar
        open={openSnackbar}
        onClose={onCloseAlert}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{ width: '50%', maxWidth: '50%', textAlign: 'center' }}
      >
        <Alert onClose={onCloseAlert} severity="error" sx={{ textAlign: 'center' }}>
          User {user?.email} not authorized. Please contact Goch Capital to authorize your account.
        </Alert>
      </Snackbar>

      <Avatar
        alt="Company Logo"
        src={`../../../assets/Goch Capital 500.jpg`}
        sx={{ width: 100, height: 100, margin: '0 auto' }} // Adjust marginTop for positioning
      />
      <Typography variant="h2" color={colors.grey[100]} width='100%' sx={{ width: '100%', maxWidth: '90%', mt: 5, mb: 2 }}>
        Welcome
      </Typography>
      <Typography variant="h5" color={colors.grey[400]} sx={{ width: '100%', maxWidth: '90%', mt: 1, mb: 8 }}>
        Please Log in to Goch Capital Platform
      </Typography>

      <Button
        sx={{
          backgroundColor: "#122230",
          color: "white",
          fontSize: "14px",
          fontWeight: "bold",
          padding: "10px 20px",
          width: '80%',
          mt: 3,
          borderRadius: '15rem',
          '&:hover': {
            backgroundColor: '#1b354d', // Change to your desired hover color
            // You can also add other style changes you want on hover
          },
          
           // Button width 80% of its container
        }}
        onClick={handleLogin}
      >
        <LoginIcon sx={{ mr: "10px" }} />
        Log in
      </Button>
    </Box>
    </Box>



    );
};

export default LoginPage;
