import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { configureStore } from "@reduxjs/toolkit";
import { Provider } from "react-redux";
import { setupListeners } from "@reduxjs/toolkit/query";
import { api } from "./state/api";
// import { AuthProvider } from './AuthContext';
import {Auth0Provider} from '@auth0/auth0-react'
import { UserProvider } from "./UserContext";
import { MobileProvider } from './MobileContext';


const store = configureStore({
  reducer: {
    // global: globalReducer,
    [api.reducerPath]: api.reducer,
  },
  middleware: (getDefault) => getDefault().concat(api.middleware),
});
setupListeners(store.dispatch);

const root = ReactDOM.createRoot(document.getElementById("root"));

const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
const domain = process.env.REACT_APP_AUTH0_DOMAIN;
root.render(
  <React.StrictMode>


    <Provider store={store}>

      <Auth0Provider 
    domain={domain}
    clientId={clientId}
    useRefreshTokens={true}  
    cacheLocation="localstorage" 
    onRedirectCallback={window.location.origin}
    authorizationParams={{
      redirect_uri: window.location.origin
    }}
 >
        <BrowserRouter>

            <UserProvider>
              <MobileProvider>

        <App />
        </MobileProvider>
        </UserProvider>
        </BrowserRouter>

        </Auth0Provider>

    </Provider>

  </React.StrictMode>
);
