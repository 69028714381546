import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const api = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: (headers) => {
      // Add headers here if needed, for example, authorization headers
      headers.set('Cache-Control', 'no-cache');
      const token = process.env.REACT_APP_API_TOKEN; 
      // If the token is available, set the Authorization header
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },  
  }),
  reducerPath: "adminApi",
  tagTypes: ["User"],
  endpoints: (build) => ({
    getUserId: build.query({
      query: (id) => `client/user/${id}`,
      providesTags: ["User"],
    }),
    getDeals: build.query({
      query: () => "client/deals",
      providesTags: ["Deals"],
    }),
    getNotes: build.query({
      query: () => "client/notes",
      providesTags: ["Notes"],
    }),  
    getInvestors: build.query({
      query: () => "client/investors",
      providesTags: ["Investors"],
    }),
    getInvestments: build.query({
      query: () => "client/investments",
      providesTags: ["Investments"],
    }),   
    getTotalInvestments: build.query({
      query: () => "client/total/investments",
      providesTags: ["TotalInvestments"],
    }),     
    getPerformance: build.query({
      query: () => "client/performance",
      providesTags: ["Performance"],
    }),

    getDashboardData: build.query({
      query: (investorId) => `client/dashboard/data/${investorId}`,
      providesTags: ["Dashboard"],
    }),      

    getDashboardDataAdmin: build.query({
      query: () => `client/dashboard/data`,
      providesTags: ["DashboardAdmin"],
    }), 
    getRequestsData: build.query({
      query: (investorId) => `client/requests/data/${investorId}`,
      providesTags: ["Requests"],
    }),      

    getRequestsDataAdmin: build.query({
      query: () => `client/requests/data`,
      providesTags: ["RequestsAdmin"],
    }),       
    
    postNotesDB: build.mutation({
      query: (noteData) => ({
        url: "updatedb/notes/create",
        method: 'POST',
        body: noteData,
      }),
      invalidatesTags: ["Notes"], // Assuming this operation should refresh the cached notes
    }),   
    updateNote: build.mutation({
      query: (note) => ({
        url: "updatedb/notes/modify",
        method: 'PUT', 
        body: note,
      }),
    }),        
    deleteNote: build.mutation({
      query: (id) => ({
        url: `updatedb/notes/delete/${id}`,
        method: 'DELETE', 
      }),
    }),    
    createNewUser: build.mutation({
      query: (userData) => ({
        url: "updatedb/users/create",
        method: 'POST',
        body: userData,
      }),
      invalidatesTags: ["createUser"], // Assuming this operation should refresh the cached notes
    }), 
    updateUser: build.mutation({
      query: (user) => ({
        url: "updatedb/users/modify",
        method: 'PUT', 
        body: user,
      }),
    }), 
    deleteUser: build.mutation({
      query: (id) => ({
        url: `updatedb/users/delete/${id}`,
        method: 'DELETE', 
      }),
    }), 
    createNewInvestment: build.mutation({
      query: (investmentData) => ({
        url: "updatedb/investments/allocate",
        method: 'POST',
        body: investmentData,
      }),
      invalidatesTags: ["allocateInvestment"], // Assuming this operation should refresh the cached notes
    }), 
    modifyInvestment: build.mutation({
      query: (investmentData) => ({
        url: "updatedb/investments/modify",
        method: 'PUT',
        body: investmentData,
      }),
      invalidatesTags: ["modifyInvestment"],
    }), 
    deleteInvestment: build.mutation({
      query: (id) => ({
        url: `updatedb/investments/delete/${id}`,
        method: 'DELETE', 
      }),
      invalidatesTags: ["deleteInvestment"], // Assuming this operation should refresh the cached notes
    }), 
    createNewRequest: build.mutation({
      query: (requestData) => ({
        url: "updatedb/requests/allocate",
        method: 'POST',
        body: requestData,
      }),
      invalidatesTags: ["allocateRequest"], // Assuming this operation should refresh the cached notes
    }), 
    modifyRequest: build.mutation({
      query: (requestData) => ({
        url: "updatedb/requests/modify",
        method: 'PUT',
        body: requestData,
      }),
      invalidatesTags: ["modifyRequest"],
    }), 
    deleteRequest: build.mutation({
      query: (id) => ({
        url: `updatedb/requests/delete/${id}`,
        method: 'DELETE', 
      }),
      invalidatesTags: ["deleteRequest"], // Assuming this operation should refresh the cached notes
    }),
  }),
});


export const { useGetDealsQuery,
  useGetNotesQuery,
useGetUserIdQuery,
useGetInvestorsQuery,
useGetInvestmentsQuery,
useGetTotalInvestmentsQuery,
useGetPerformanceQuery,
useGetDashboardDataQuery,
useGetDashboardDataAdminQuery,
useGetRequestsDataAdminQuery,
useGetRequestsDataQuery,
usePostNotesDBMutation,
useUpdateNoteMutation,
useDeleteNoteMutation,
useCreateNewUserMutation,
useUpdateUserMutation,
useDeleteUserMutation,
useCreateNewInvestmentMutation,
useDeleteInvestmentMutation,
useModifyInvestmentMutation,
useCreateNewRequestMutation,
useDeleteRequestMutation,
useModifyRequestMutation} = api;
