import React, { useState, useMemo } from "react";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import {
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import DealCard from "../../components/DealCard";
import DatePickerComponent from "../../components/DatePicker";
import PrimarySearchAppBar from "../../components/SearchBar";
import { useGetDealsQuery } from "../../state/api";

const Filters = ({ filterName, values, selectedValue, onSelectFilter }) => {
  return (
    <Box sx={{ minWidth: 120, marginRight: 2 }}>
      <FormControl fullWidth>
        <InputLabel id={`filter-label-${filterName}`}>{filterName}</InputLabel>
        <Select
          labelId={`filter-label-${filterName}`}
          id={`filter-select-${filterName}`}
          value={selectedValue}
          label={filterName}
          onChange={(e) => onSelectFilter(e.target.value)}
        >
          {values.map((value) => (
            <MenuItem key={value} value={value}>
              {value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

const DealRoom = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobile = useMediaQuery("(min-width: 1000px)");
  const { data, isLoading } = useGetDealsQuery();
  // State for filters
  const [selectedSize, setSelectedSize] = useState("");
  const [selectedSegment, setSelectedSegment] = useState(null);
  const [selectedIRR, setSelectedIRR] = useState(null);
  const [selectedDuration, setSelectedDuration] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  // console.log("data",data)
  // Apply filters to data
  const filteredData = useMemo(() => {
    let filtered = Array.isArray(data) ? [...data] : [];
    if (selectedSize !== "") {
      filtered = filtered.filter((deal) => deal.totaldealsize === selectedSize);
    }

    if (selectedSegment) {
      filtered = filtered.filter((deal) => deal.segment === selectedSegment);
    }

    if (selectedIRR) {
      filtered = filtered.filter((deal) => deal.expectedirr === selectedIRR);
    }

    if (selectedDuration) {
      filtered = filtered.filter((deal) => deal.duration === selectedDuration);
    }

    if (selectedDate) {
      // console.log("selected date",selectedDate)
      // console.log("typeof selected date",typeof selectedDate)
      // console.log("real closedate",  filtered.closedate)
      // console.log("typeof closedate",typeof filtered.closedate)
      filtered = filtered.filter((deal) => deal.closedate === selectedDate.toISOString());
    }

    return filtered;
  }, [data, selectedSize, selectedSegment, selectedIRR, selectedDuration, selectedDate]);

  return (
    <Box m="50px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="DEALROOM" />
      </Box>
      {data || !isLoading ? (
        <Box justifyContent="flex-start">
          {/* Filter Bar */}
          <Box height="100%" width="100%" display="flex" mb="8px">
          <DatePickerComponent value={selectedDate} onSelectDate={setSelectedDate} />
            <PrimarySearchAppBar />
          </Box>
          <Box width="100%" display="flex" paddingRight={10}>
            {/* Size Filter */}
            <Filters
              filterName="Size"
              values={[10, 20, 30]}
              selectedValue={selectedSize}
              onSelectFilter={setSelectedSize}
            />
            <Filters
              filterName="Segment"
              values={[
                "Consumer Loans",
                "Trade Finance",
                "Real Estate Loans",
                "Venture Debt",
              ]}
              selectedValue={selectedSegment}
              onSelectFilter={setSelectedSegment}
            />
            <Filters
              filterName="Expected IRR"
              values={["5-7%", "8-10%", "10%+"]}
              selectedValue={selectedIRR}
              onSelectFilter={setSelectedIRR}
            />
            <Filters
              filterName="Duration (Y)"
              values={[120, 1.5, 2, 3, 4]}
              selectedValue={selectedDuration}
              onSelectFilter={setSelectedDuration}
            />
          </Box>

          {/* Deals */}
          <Box
            mt="25px"
            display="grid"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            justifyContent="space-between"
            rowGap="20px"
            columnGap="1.33%"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >
            {filteredData.map(
              ({
                dealid,
                dealname,
                description,
                totaldealsize,
                spread,
                minimumparticipationamount,
                duration,
                closedate,
                progress,
                segment,
              }) => (
                <DealCard
                  key={dealid}
                  title={dealname}
                  subtitle={description}
                  segment={segment}
                  totaldealsize={totaldealsize}
                  minimumparticiapationamount={minimumparticipationamount}
                  spread={spread}
                  duration={duration}
                  closedate={closedate}
                  progress={progress}
                />
              )
            )}
          </Box>
        </Box>
      ) : (
        <Typography>Loading...</Typography>
      )}
    </Box>
  );
};

export default DealRoom;
