import React from 'react'
import { Box, Typography, TextField, MenuItem} from "@mui/material";
import { Field, Formik, useFormikContext } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../../components/Header";
import InputAdornment from '@mui/material/InputAdornment';
// import { useCreateNewUserMutation } from "../../../state/api";
import Alert from '@mui/material/Alert';
import { LoadingButton } from '@mui/lab';
import { useState, useMemo } from "react";
import { useGetNotesQuery,useGetInvestorsQuery,useGetTotalInvestmentsQuery,useCreateNewInvestmentMutation } from "../../../state/api";

const AddInvestmentPage = ({onInvestmentAdded }) => {

    const isNonMobile = useMediaQuery("(min-width:600px)");
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState(false);
    // const { values, setFieldValue } = useFormikContext(); 
    const { data: notesData, isLoading: isLoadingNotes } = useGetNotesQuery();
    const { data: investorsData, isLoading: isLoadingInvestors } = useGetInvestorsQuery();
    const { data: InvestmentsData, isLoading: isLoadingInvestments } = useGetTotalInvestmentsQuery();
    const [createNewInvestment, { isLoading, isSuccess, isError }] = useCreateNewInvestmentMutation();
    // const { setFieldValue,handleChange } = useFormikContext();

    // const handleNoteChange = (event) => {
    //   const noteId = event.target.value;
    //   const selectedNote = notesData.find((note) => note.noteid === noteId);
    
    //   // Call the form's existing handleChange function to update noteid
    //   handleChange(event); // This ensures Formik's own internal state is updated
    
    //   // If the note is found, update the noteprice in the form
    //   if (selectedNote) {
    //     const latestPrice = getLatestPrice(selectedNote);
    //     console.log(latestPrice)
    //     setFieldValue('noteprice', latestPrice); // Update the noteprice field
    //   }
    // };
    

  
    const handleFormSubmit = async (values, { resetForm }) => {
      console.log("Investment has been allocated", values);
      setLoading(true);
      
      try {
          const newInvestment = await createNewInvestment(values).unwrap();
          console.log("new Investment: " , newInvestment)
          console.log('Investment uploaded successfully');
          setAlert({ show: true, message: 'Investment was allocated successfully.', severity: 'success' });
          setLoading(false);
          onInvestmentAdded(newInvestment.data)
          resetForm();
          
          // Handle success, e.g., showing a success message or redirecting
          } catch (error) {
              console.error('Error submitting the form:', error);
              setLoading(false);
              setAlert({ show: true, message: 'Error check required fields', severity: 'error' });
          // Handle error, e.g., showing an error message
          }
      };    
      const checkoutSchema = yup.object().shape({
        investorid: yup.string().required('Investor is required'),
        noteid: yup.string().required('Note is required'),
        expirationDate: yup.date().required("invalid date"),
        investmentamount:   yup.number(),
        currency: yup.string().required('Currency is required'),
        noteprice : yup.number()
        .required('Investment amount is required')
        .positive('Investment amount must be positive')
        .test(
          'maxAmount',
          'Investment exceeds the maximum allowed for this note',
          function(value) {
            // console.log("noteid: ", this.parent.noteid);
            // console.log("notes Data", notesData)
            const note = notesData.find(n => n.noteid.toString() === this.parent.noteid.toString());
            // console.log("note: ",note)
            const maxInvestment = getMaxInvestableAmount(note.noteid)
            // const latestPrice = getLatestPrice(note.noteid)

            // console.log(`Value: ${value}, MaxInvestment: ${maxInvestment}`);
            return note ? value <= maxInvestment: false;
        }
        ),
      });
      // const checkoutSchema = yup.object({
      //   investorid: yup.string().required('Investor is required'),
      //   noteid: yup.string().required('Note is required'),
      //   expirationDate: yup.date().required("Invalid date"),
      //   investmentamount: yup.number()
      //     .required('Investment amount is required')
      //     .positive('Investment amount must be positive'),
      //   noteprice: yup.number()
      //     .required('Note price is required')
      //     .positive('Note price must be positive')
      //     .test(
      //       'maxPrice',
      //       'Note price exceeds the latest price for this note',
      //       function(value) {
      //         const noteid = this.parent.noteid;
      //         console.log("noteid", noteid) // Access the noteid from the form state
      //         if (!noteid) {
      //           // Can't perform validation without noteid, consider it a pass and validate elsewhere
      //           return true; 
      //         }
      //         const note = notesData.find(n => n.noteid.toString() === noteid.toString());
      //         if (!note) {
      //           // Note not found, can't validate yet
      //           console.log("here")
      //           return this.createError({
      //             path: 'noteprice',
      //             message: `Note not found for ID: ${noteid}`,
      //           });
      //         }
      //         const maxInvestment = getMaxInvestableAmount(note.noteid);
      //         const latestPrice = getLatestPrice(note.noteid);
      
      //         // Ensure the entered value does not exceed the latest price
      //         if (value > latestPrice) {
      //           return this.createError({
      //             path: 'noteprice',
      //             message: `Price exceeds the latest price of ${latestPrice}`,
      //           });
      //         }
      
      //         // Perform other checks as necessary
      //         return value <= maxInvestment;
      //       }
      //     ),
      // });      
      const initialValues = {
        investorid: '',
        noteid: '',
        expirationDate: new Date().toISOString().slice(0, 10),
        investmentamount: 0,
        noteprice: '',
        currency: ''
      };  

      const getMaxInvestableAmount = (noteid) => {
        const investment = InvestmentsData.find(investment => investment.noteid === noteid);
        return investment ? investment.max_investable_amount : 0;
      };

      const getLatestPrice = (noteid) => {
        const investment = InvestmentsData.find(investment => investment.noteid === noteid);
        return investment ? investment.notelatestprice : 0;
      };

    return (
      <Box m="20px">
        <Header title="Allocate Investment" subtitle="Allocate Investment" />
        {(notesData && investorsData && InvestmentsData) && (!isLoadingNotes && !isLoadingInvestors && !isLoadingInvestments) ? (   

        <Formik
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          validationSchema= {checkoutSchema}
          // Ensure to include validation context if needed during validation calls
        //   validate={(values) => {
        //     const schema = checkoutSchema(notesData, InvestmentsData);
        //     return schema.validate(values, { context: { notesData, InvestmentsData } });
        //   }} //{checkoutSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                }}
              >

            <TextField 
            select 
            name="investorid"
            variant="filled"
            type="text"
            label="Investor"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.investorid}
            error={!!touched.investorid && !!errors.investorid}
            helperText={touched.investorid && errors.investorid}
            sx={{ gridColumn: "span 2" }}
            fullWidth>
            <MenuItem value="">Select an Investor</MenuItem>
            {investorsData.map(investor => (
              <MenuItem key={investor.investorid} value={investor.investorid}>
                {investor.name}
              </MenuItem>
            ))}
            </TextField>
            <TextField 
            select
            name="noteid"             
            variant="filled"
            type="text"
            label="Note"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.noteid}
            error={!!touched.noteid && !!errors.noteid}
            helperText={touched.noteid && errors.noteid}
            sx={{ gridColumn: "span 2" }}
            fullWidth>
            <MenuItem value="">Select a Note</MenuItem>
            {notesData.map(note => (
              <MenuItem key={note.noteid} value={note.noteid}>                
                {note.name} (Max: ${getMaxInvestableAmount(note.noteid)})
              </MenuItem>

            ))}
          </TextField>

          <TextField 
          type="number" 
          name="investmentamount" 
          variant="filled"
          label="Investment Amount"
          onBlur={handleBlur}
          onChange={handleChange}
          InputProps={{
          // startAdornment: <InputAdornment position="start">ILS</InputAdornment>,
        }} 
          value={values.investmentamount}
          error={!!touched.investmentamount && !!errors.investmentamount}
          helperText={touched.investmentamount && errors.investmentamount}
          sx={{ gridColumn: "span 2" }}
          fullWidth/>    

<TextField
                  fullWidth
                  variant="filled"
                  select                  
                  label="Currency"    
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.investmentprofile}
                  name="currency"
                  error={!!touched.currency && !!errors.currency}
                  helperText={touched.currency && errors.currency}
                  sx={{ gridColumn: "span 2" }}
            >
                <MenuItem key="1" value="ILS">
                  ILS
                </MenuItem>
                <MenuItem key="2" value="$">
                  $
                </MenuItem>
                <MenuItem key="3" value="€">
                  €
                </MenuItem>
                <MenuItem key="4" value="£">
                £
                </MenuItem>

            </TextField>

<TextField 
          type="number" 
          name="noteprice" 
          variant="filled"
          label="Note Price"
          onBlur={handleBlur}
          onChange={handleChange}
        //   InputProps={{
        //   startAdornment: <InputAdornment position="start">ILS</InputAdornment>,
        // }} 
          value={values.noteprice}
          error={!!touched.noteprice && !!errors.noteprice}
          helperText={touched.noteprice && errors.noteprice}
          sx={{ gridColumn: "span 2" }}
          fullWidth/> 

            <TextField
                fullWidth
                id="standard-start-adornment"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                type="date"
                label="Investment Date"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.investmentdate}
                name="investmentdate"
                error={!!touched.investmentdate && !!errors.investmentdate}
                helperText={touched.investmentdate && errors.investmentdate}
                sx={{ gridColumn: "span 2" }}
              />  
  
              </Box>
              <Box display="flex" justifyContent="end" mt="20px">
  
              <LoadingButton
          type="submit"        
          loading={loading}
          color="secondary"
          variant="contained"
        >
          Allocate Investment
        </LoadingButton>
        {alert.show && <Alert severity={alert.severity}>{alert.message}</Alert>}
              </Box>
            </form>
          )}
        </Formik>
        ) : (
        <Typography>Loading...</Typography>
        )}
      </Box>
    );
  };
  
  



export default AddInvestmentPage;
