import {useTheme,Box} from '@mui/material';
import { ResponsivePie } from '@nivo/pie'
import { tokens } from "../theme";
import { Dashboard } from '@mui/icons-material';
import {mockPieData as data} from "../data/mockData";
import { useMobile } from '../MobileContext';




const PieChart = ({isDashboard = false, data=data}) => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode)   
    const isMobile = useMobile()
    const CustomTooltip = ({ id, value, color }) => {
        
        const formattedValue = `${value*100}%`
        return(        <div style={{ color: colors.grey[100], background: "white", padding: '5px 10px', borderRadius: '5px' }}>
              <Box display="flex" alignItems="center">
    {/* Colored Circle on the left */}
    <div style={{ width: "10px", height: "10px", borderRadius: "50%", background: color, marginRight: "10px" }}></div>
        <div>
        <strong style={{ color: colors.primary[500] }}>Asset:  </strong> <span style={{ color: colors.primary[500] }}>{id}</span><br/> <strong style={{ color: colors.primary[500] }}>Value: </strong><span style={{ color: colors.primary[500] }}>{formattedValue}</span >
        </div>
        </Box>
    </div>)

    };

    return (
        <ResponsivePie
        data={data}
        theme={{
            axis: {
                domain: {
                    line: {
                        stroke: colors.grey[100]
                    }
                },
                legend: {
                    text: {
                        fill: colors.grey[100]
                    }
                },
                ticks: {
                    line: {
                        stroke: colors.grey[100],
                        strokeWidth: 1
                    },                    
                    text: {
                        fill: colors.grey[100]
                    }
                }, 
            legends: {
                text: {
                    fill: colors.grey[100],
                    fontSize: isMobile ? 8 : 10,
                }
            }              
            },
            tooltip: {
                container: {
                    fill: colors.grey[500]
                }
            }
        }}
        tooltip={({ datum }) => <CustomTooltip id={datum.id} value={datum.value} color={datum.color} />}
        margin={{ top: 40, right: 0, bottom: 100, left: 0 }}
        colors={{ scheme: 'dark2' }}

        innerRadius={0.5}
        padAngle={0.7}
        cornerRadius={3}
        activeOuterRadiusOffset={8}
        borderWidth={1}
        borderColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    0.2
                ]
            ]
        }}
        valueFormat={function (e) {
            return Math.round(e*100).toFixed(2) + '%'
          }}
        arcLabel={(item) => `${item.value}%`}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextColor= {colors.grey[100]}
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: 'color' }}
        enableArcLabels={false}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    2
                ]
            ]
        }}
        defs={[
            {
                id: 'dots',
                type: 'patternDots',
                background: 'inherit',
                color: 'rgba(255, 255, 255, 0.3)',
                size: 4,
                padding: 1,
                stagger: true
            },
            {
                id: 'lines',
                type: 'patternLines',
                background: 'inherit',
                color: 'rgba(255, 255, 255, 0.3)',
                rotation: -45,
                lineWidth: 6,
                spacing: 10
            }
        ]}
        legends={[
            {
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 0,
                translateY: 56,
                itemsSpacing: 0,
                itemWidth: 100,
                itemHeight: 18,
                itemTextColor: colors.grey[100],
                itemDirection: 'left-to-right',
                itemOpacity: 1,
                symbolSize: 8,
                symbolShape: 'circle',
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemTextColor: '#3333'
                        }
                    }
                ]
            }
        ]}
    />
    )
} 

export default PieChart;