import { useState,useEffect } from "react";
import {ProSidebar, Menu, MenuItem} from "react-pro-sidebar";
import 'react-pro-sidebar/dist/css/styles.css';
import {Box, IconButton, Typography, useTheme} from '@mui/material';
import { Link } from "react-router-dom";
import { tokens } from "../../theme";
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';
import HandshakeIcon from '@mui/icons-material/Handshake';
import AssignmentIcon from '@mui/icons-material/Assignment';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import {useUser} from '../../UserContext';
import { useMobile } from '../../MobileContext';



const Item =({title, to, icon, selected, setSelected}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    return(
        <MenuItem active={selected===title} style={{color: colors.grey[100]}} onClick={() => setSelected(title)} icon={icon}>
        <Typography>{title}</Typography>
            <Link to={to}/>
        
        </MenuItem>
    )
}


const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useMobile(); // Use the custom hook
  const [isCollapsed, setIsCollapsed] = useState(isMobile);
  const [selected, setSelected] = useState("Dashboard");
  const { userData,user } = useUser();
  const role = userData?.rows?.[0]?.role || 'nan';
  const sidebarStyle = {
    width: isMobile ? '60px' : '250px', // Adjust these values as needed
    minWidth: isMobile ? '60px' : '250px', // Ensure the sidebar doesn't shrink below the desired width
  };

  return (
    <Box
    sx={{
      height: "100vh",
      position: "sticky",
      display: "flex",
      top: 0,
      bottom: 0,
      zIndex: 10000,
      borderRadius: "1rem",
  boxShadow: "0.05rem 0.1rem 0.15rem 0.1rem rgba(0, 0, 0, .15)",

      "& .pro-sidebar-inner": {
        background: `${colors.primary[400]} !important`,
      },
      "& .pro-icon-wrapper": {
        backgroundColor: "transparent !important",
      },
      "& .pro-inner-item": {
        padding: "5px 35px 5px 20px !important",
      },
      "& .pro-inner-item:hover": {
        color: "#4cceac !important",
      },
      "& .pro-menu-item.active": {
        color: "#2e7c67 !important",
      },
      "& .pro-menu-item.active": {
        color: "#2e7c67 !important",
      },
    }}
  >
    <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape = "square">
        {isCollapsed ?    <Box display="flex" justifyContent="center" alignItems="center" ml ="-5px" mt="15px">
          <img
                        m = "10"
                        alt="profile-user"
                        width="40px"
                        height="40px"
                        src={`../../assets/Goch Capital 500.jpg`}
                        style={{curso: "pointer", borderRadius: "50%"}}
                        />
                        </Box> : undefined}
            <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon = {isCollapsed ? <MenuOutlinedIcon/> : undefined}
            style = {{
                margin: "25px 20px 20px 0",
                color: colors.grey[100],
            }}
            >
                {!isCollapsed && (
                    <Box display="flex" justifyContent="space-between" alignItems="center" ml ="30px">
                        <img
                        alt="profile-user"
                        width="80px"
                        height="80px"
                        src={`../../assets/Goch Capital 500.jpg`}
                        style={{curso: "pointer", borderRadius: "50%"}}
                        />
                        {/* <Typography variant="h3" color={colors.grey[100]} marginLeft={-5}>
                            Goch Capital
                        </Typography> */}
                        <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                            <MenuOutlinedIcon/>
                        </IconButton>
                    </Box>
                )}
            </MenuItem>
            <Box mb = "50px" />
            <Box paddingLeft={isCollapsed? undefined : "10%"}> 
                <Item 
                title = "Dashboard"
                to = "/"
                icon = {<HomeOutlinedIcon/>}
                selected={selected}
                setSelected={setSelected}
                />
                <Item 
                title = "Notes"
                to = "/notes"
                icon = {<AssignmentIcon/>}
                selected={selected}
                setSelected={setSelected}
                />   
                {role === "admin" && (
                 <Item 
                title = "Users"
                to = "/users"
                icon = {<GroupOutlinedIcon/>}
                selected={selected}
                setSelected={setSelected}
                />  
                )}

                <Item 
                title = "Investments"
                to = "/investments"
                icon = {<HandshakeIcon/>}
                selected={selected}
                setSelected={setSelected}
                />   
                <Item 
                title = "Requests"
                to = "/requests"
                icon = {<RequestQuoteOutlinedIcon/>}
                selected={selected}
                setSelected={setSelected}
                />       
                                {/* <Item 
                title = "Reports"
                to = "/report"
                icon = {<RequestQuoteOutlinedIcon/>}
                selected={selected}
                setSelected={setSelected}
                />                     */}
                                                                     
            </Box>
        </Menu>
    </ProSidebar>
    </Box>

);
};
export default Sidebar;