import React from 'react'
import { Box, Typography, Dialog, DialogContent ,CircularProgress} from "@mui/material";
import {
  DataGrid,
  GridToolbar,
  GridRowModes,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from "@mui/x-data-grid";
import { useEffect, useMemo } from "react";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import { useMobile } from '../../MobileContext';
import { useGetDashboardDataAdminQuery,useModifyInvestmentMutation,useDeleteInvestmentMutation,useGetDashboardDataQuery } from "../../state/api";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import Button from "@mui/material/Button";
import AddInvestmentPage from "../forms/addInvestment";
import { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import {useUser} from '../../UserContext';
import {calculateInvestmentsYTM} from "../../metricsCalculation";
import { ContactSupportOutlined } from '@mui/icons-material';


const Investments = ({ isDashboard = false , dashboardData}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isMobile = useMobile()
    const {user,userData,setUserData} = useUser();
    const role = userData ? userData.rows[0].role :  'nan';
  // console.log(role)
    const investorId = userData ? userData.rows[0].investorid :  'nan';
    // console.log(investorId)
    const { data, isLoading } = useDashboardData(role,investorId)
    // const { data, isLoading } = useGetDashboardDataQuery({investorId})
    // console.log(data)
    // console.log("user investment", data ? data.rows[0].init_invest_price :  'nan')
    useEffect(() => {
      if (role === 'admin' && isDashboard) {
      console.log('dashboardData updated:', dashboardData);
      setRows(dashboardData)
      }
    }, [dashboardData]);


    const getRowId = (row) => {
      if (isDashboard) {
        if (role === 'admin') {
          return `${row.noteid}-${row.date}`;
        } else {
          return `${row.investmentid}-${row.reportid}`;
        }
      } else {
        return row.investmentid;
      }
    } ;
    // const calcCoupunReduction =  data ? parseFloat((data[0].init_invest_price - data[0].init_note_price) / data[0].init_note_price) * 100 : 'nan';

    const [updateInvestment, { isLoadingUpdate, isSuccess, isError }] =
    useModifyInvestmentMutation();
    const [deleteInvestment, { isLoadingDelete, isSuccessDelete, isErrorDelete }] =
      useDeleteInvestmentMutation();

    const [open, setOpen] = useState(false);
    const [rows, setRows] = useState([]);
    const [rowModesModel, setRowModesModel] = useState({});



    // function findMetricsForInvestment(investmentid, metrics) {
    //   // console.log("metrics",metrics)
    //   // console.log("investmentId",investmentId)
    //   // console.log("metrics investment id",metrics[0].investmentid)

    //   return metrics.find(metric => metric.investmentid === investmentid) || {};
    // }
    function findMetricsForInvestment(investmentid, latestPerformanceDate, metrics) {
      return metrics.find(
        metric =>
          metric.investmentid === investmentid &&
          metric.latest_performance_date === latestPerformanceDate
      ) || {};
    }

    let updatedData;
if (isDashboard) 
  // Deduplicate data by reportid
  // const deduplicatedData = Array.isArray(data) ? (() => {
  //   const reportIdMap = new Map();
  //   data.forEach(item => {
  //     if (!reportIdMap.has(item.reportid)) {
  //       reportIdMap.set(item.reportid, item);
  //     }
  //   });
  //   return Array.from(reportIdMap.values());
  // })() : [];


  // console.log("deduplicatedData",deduplicatedData)
  // const calculateMetrics = data ? calculateInvestmentsYTM(data) : null;
  // console.log("calculateMetrics",calculateMetrics)

  // updatedData = data ? data.map(entry => {
  //   const metrics = findMetricsForInvestment(entry.investmentid, entry.latest_performance_date, calculateMetrics);
  //   return Object.assign({}, entry, {
  //     investor_nav : metrics.investor_nav,
  //     total_return: metrics.total_return ? (metrics.total_return * 100).toFixed(2) + '%' : null,
  //     yieldToMaturity: metrics.yieldToMaturity

  //   });
  // }) : 'nan';
  if (role === 'admin') {
    if(dashboardData){

  updatedData = dashboardData
}} else {
  const calculateMetrics = data ? calculateInvestmentsYTM(data) : null;

  updatedData = data ? data.map(entry => {
    const metrics = findMetricsForInvestment(entry.investmentid, entry.latest_performance_date, calculateMetrics);
    return Object.assign({}, entry, {
      investor_nav : metrics.investor_nav,
      total_return: metrics.total_return ? (metrics.total_return * 100).toFixed(2) + '%' : null,
      yieldToMaturity: metrics.yieldToMaturity

    });
  }) : 'nan';
} else {
  // Filter data for entries with rn === "1"
  const filteredData = Array.isArray(data) ? data.filter(item => item.rn === "1") : [];
  // console.log("filteredData",filteredData)

  const calculateMetrics = filteredData ? calculateInvestmentsYTM(filteredData) : null;
  // console.log("calculateMetrics",calculateMetrics)

  updatedData = filteredData ? filteredData.map(entry => {
    const metrics = findMetricsForInvestment(entry.investmentid, entry.latest_performance_date, calculateMetrics);
    return Object.assign({}, entry, {
      investor_nav : metrics.investor_nav,
      total_return: metrics.total_return ? (metrics.total_return * 100).toFixed(2) + '%' : null,
      yieldToMaturity: metrics.yieldToMaturity
    });
  }) : 'nan';
}


  // console.log("updated_data", updatedData)
  


  function useDashboardData(userRole,investorId) {
    const adminQueryResult = useGetDashboardDataAdminQuery(investorId);
    const userQueryResult = useGetDashboardDataQuery(investorId);
    const filterByDate = (data) => {
      return data.filter(item => new Date(item.latest_performance_date) >= new Date(item.investmentdate));
  };
    if (userRole === 'admin') {
      return adminQueryResult;
    } else {
      const filteredData = filterByDate(userQueryResult.data || []);
      return {...userQueryResult, data: filteredData};
    }
  }

    if (!isLoading && data && rows.length === 0) {
      setRows(updatedData);
    }
  

  
    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };
  
    const handleInvestmentAllocation = (newInvestment) => {
      // queryClient.invalidateQueries('getDashboardDataAdmin');
      setRows((prevRows) => [...prevRows, newInvestment]);
    };
  
    const handleRowEditStop = (params, event) => {
      if (params.reason === GridRowEditStopReasons.rowFocusOut) {
        event.defaultMuiPrevented = true;
      }
    };
  
    const handleEditClick = (id) => () => {
      console.log("handle edit click", id);
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };
  
    const handleSaveClick = (id) => () => {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };
  
    const handleDeleteClick = (id) => async () => {
      try {
        // If deleteNote is successful, filter out the deleted row
        setRows(rows.filter((row) => row.investmentid !== id));
        // Assuming deleteNote makes an API call and returns a promise
        await deleteInvestment(id);
      } catch (error) {
        console.error("Failed to delete note", error);
        // Handle any errors, e.g., showing an error message to the user
      }
    };
  
    const handleCancelClick = (id) => () => {
      setRowModesModel({
        ...rowModesModel,
        [id]: { mode: GridRowModes.View, ignoreModifications: true },
      });
      const editedRow = rows.find((row) => row.investmentid === id);
      if (editedRow.isNew) {
        setRows(rows.filter((row) => row.investmentid !== id));
      }
    };
  
    const processRowUpdate = async (newRow) => {
      const updatedRow = { ...newRow, isNew: false };
      try {
        await updateInvestment(updatedRow).unwrap();
      } catch (error) {
        console.error("Failed to update investment", error);
      }
      setRows(
        rows.map((row) =>
          row.investmentid === newRow.investmentid ? updatedRow : row
        )
      );
      return updatedRow;
    };
  
    const handleRowModesModelChange = (newRowModesModel) => {
      setRowModesModel(newRowModesModel);
    };
    let columns;
    if (isDashboard)
      if (role === 'admin') {

      columns = [
        { field: "isin", headerName: "Note", flex: 1 },
        { field: "date", headerName: "Report Date",type:"date",valueGetter: ({ value }) => value && new Date(value), flex: 1 },
        {
          field: "total_invested",
          headerName: "NAV",
          flex: 1,
          headerAlign: "left",
          align: "left",
          type: "number",
          valueGetter: (params) => {
            const investmentNAV = parseFloat(params.row.total_invested * (1 + params.row.total_return));
            const currency = params.row.currency;

            if (investmentNAV == null || currency == null) {
              return "";
            }
            return `${((investmentNAV / 1000)).toLocaleString()}K ${currency}`;
          },
          // editable: true,
        },        // Add `note_price` column as per requirement. Adjust `field` and other properties as necessary.
        { field: "adj_noteprice", headerName: "Note Price", type: "number", flex: 1,        valueFormatter: (params) => {
          if (params.value == null) {
            return "";
          }
          return `${(Math.round(params.value * 1000)/1000).toLocaleString()}`;
        }, },
      ];
    } 
    else {

      columns = [
        { field: "note_name", headerName: "Note", flex: 1 },
        { field: "latest_performance_date", headerName: "Report Date",type:"date",valueGetter: ({ value }) => value && new Date(value), flex: 1 },
        {
          field: "investor_nav",
          headerName: "NAV",
          flex: 1,
          headerAlign: "left",
          align: "left",
          type: "number",
          valueGetter: (params) => {
            const investmentNAV = parseFloat(params.row.investor_nav);
            const currency = params.row.currency;

            if (investmentNAV == null || currency == null) {
              return "";
            }
            return `${((investmentNAV / 1000).toFixed(3)).toLocaleString()}K ${currency}`;
          },
          // editable: true,
        },        // Add `note_price` column as per requirement. Adjust `field` and other properties as necessary.
        { field: "adj_noteprice", headerName: "Note Price", type: "number", flex: 1,        valueFormatter: (params) => {
          if (params.value == null) {
            return "";
          }
          return `${(Math.round(params.value * 1000)/1000).toLocaleString()}`;
        }, },
      ];
    } 
    
    else {
     columns = [
      // { field: "investmentid", headerName: "ID", flex: 0.5 },
      // { field: "registrarId", headerName: "Registrar ID" },
      {
        field: "investor_name",
        headerName: "Investor",
        flex: 1,
        cellClassName: "name-column--cell",
        // editable: true,
      },
      {
        field: "note_name",
        headerName: "Note",
        flex: 1,
        headerAlign: "left",
        align: "left",
        // editable: true,
      },
      {
        field: "investmentdate",
        headerName: "Date",
        headerAlign: "left",
        align: "left",
        valueGetter: ({ value }) => value && new Date(value),
        type: "date",
        flex: 1,
        editable: role === "admin"      },
      {
        field: "investment",
        headerName: "Amount",
        headerAlign: "left",
        align: "left",
        type: "string",
        flex: 1,
        valueGetter: (params) => {
          const investmentAmount = params.row.investmentamount;
          const currency = params.row.currency;
          if (investmentAmount == null || currency == null) {
            return "";
          }
          return `${(investmentAmount / 1000).toLocaleString()}K ${currency}`;
        },
        // valueFormatter: (params) => {
        //   if (params.value == null) {
        //     return "";
        //   }
        //   return `${(params.value / 1000).toLocaleString()} K`;
        // },
        editable: role === "admin"      },
        {
          field: "init_invest_price",
          headerName: "Initial Price",
          headerAlign: "left",
          align: "left",
          type: "number",
          flex: 1,
          valueFormatter: (params) => {
            if (params.value == null) {
              return "";
            }
            return `${(params.value).toLocaleString()}`;
          },
        },
        {
          field: "quantity",
          headerName: "Position",
          headerAlign: "left",
          align: "left",
          type: "number",
          flex: 1,
          valueFormatter: (params) => {
            if (params.value == null) {
              return "";
            }
            return `${(params.value).toLocaleString()}`;
          },
        },
        {
          field: "adj_noteprice",
          headerName: "Note Price",
          headerAlign: "left",
          align: "left",
          type: "number",
          flex: 1,
          valueFormatter: (params) => {
            if (params.value == null) {
              return "";
            }
            return `${(Math.round(params.value * 1000)/1000).toLocaleString()}`;
          },
        },
        {
          field: "investor_nav",
          headerName: "NAV",
          flex: 1,
          headerAlign: "left",
          align: "left",
          type: "number",
          valueGetter: (params) => {
            const investmentNAV = params.row.investor_nav;
            const currency = params.row.currency;
            if (investmentNAV == null || currency == null) {
              return "";
            }
            return `${((investmentNAV / 1000).toFixed(2)).toLocaleString()}K ${currency}`;
          },
          // editable: true,
        },
        {
          field: "total_return",
          headerName: "Total Return",
          headerAlign: "left",
          align: "left",
          flex: 1,
          type: "number",
          valueFormatter: (params) => {
            if (params.value == null) {
              return "";
            }
            return `${params.value.toLocaleString()}`;
          },
        },
        {
          field: "yieldToMaturity",
          headerName: "YTM",
          headerAlign: "left",
          align: "left",
          flex: 1,
          type: "number",
          valueFormatter: (params) => {
            if (params.value == null) {
              return "";
            }
            return `${(Math.round(params.value*100*100)/100).toLocaleString()} %`;
          },
        },   
        {
          field: "coupon",
          headerName: "Coupon %",
          headerAlign: "left",
          align: "left",
          flex: 1,
          type: "number",
          valueFormatter: (params) => {
            if (params.value == null) {
              return "";
            }
            return `${params.value.toLocaleString()} %`;
          },
        },             
      {
        field: "amount",
        headerName: "Note Size",
        headerAlign: "left",
        align: "left",
        flex: 1,
        type: "number",
        valueGetter: (params) => {
          const noteSize = params.row.amount;
          const currency = params.row.note_currency;
          if (noteSize == null || currency == null) {
            return "";
          }
          return `${((noteSize / 1000000)).toLocaleString()}M ${currency}`;
        },
      },

      {
        field: "expirationdate",
        headerName: "Expiration Date",
        headerAlign: "left",
        align: "left",
        flex: 1,
        type: "date",
        valueGetter: ({ value }) => value && new Date(value),
      },

      {
        field: "isin",
        headerName: "ISIN",
        headerAlign: "left",
        align: "left",
        flex: 1,
      },
      {
        field: "underlying",
        headerName: "Underlying",
        headerAlign: "left",
        align: "left",
        flex: 1,
        editable: role === "admin"      },
  
    ];

  if (role === "admin") {
    columns.push({
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    }, 
    )
  }
}   
  
    return (
      <Box m="20px">
        {!isDashboard && (
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Header title="INVESTMENTS" />

        {role === "admin" && (

          <Box>
            <Button
              onClick={handleClickOpen}
              sx={{
                backgroundColor: colors.greenAccent[500],
                color: colors.grey[100],
                fontSize: isMobile ? "10px" : '10px',
                fontWeight: "bold",
                padding: "10px 20px",
                mr: "20px",
              }}
            >
              <AddOutlinedIcon sx={{ mr: "10px" }} />
              Allocate
            </Button>
            <Dialog open={open} onClose={handleClose}>
              <DialogContent>
                <AddInvestmentPage
                  onClose={handleClose}
                  onInvestmentAdded={handleInvestmentAllocation}
                />
              </DialogContent>
            </Dialog>
          </Box>
        )
            }
        </Box>
        )
}
        {rows || !isLoading ? (

          <Box
            m={isDashboard ? "5px 0 0 0" : "40px 0 0 0"}
            height={isDashboard ?  "220px" : "75vh"}
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                height: '100%', 
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .name-column--cell": {
                color: colors.greenAccent[300],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.greenAccent[700],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.greenAccent[700],
                height:`${isDashboard ? '10px' : '56px'} !important` ,
                
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
                border: "none"
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${colors.grey[100]} !important`,
                height: `${isDashboard ? 25 : 56}`,
                fontSize: isMobile ? 8 : 10
              },
            }}
          >
            <DataGrid
              rows={rows}
              columns={columns}
              editMode="row"
              initialState={isDashboard && role === 'admin' ? {
                sorting: {
                  sortModel: [{ field: 'date', sort: 'desc' }],
                },
              } : {
                sorting: {
                  sortModel: [{ field: 'latest_performance_date', sort: 'desc' }],
                },
              }}
              // initialState={isDashboard ? {
              //   sorting: {
              //     sortModel: [{ field: 'latest_performance_date', sort: 'desc' }],
              //   },
              // } : undefined}
              // getRowId={isDashboard ? (row) => `${row.investmentid}-${row.reportid}` :(row) => row.investmentid}
              getRowId = {getRowId}
              rowModesModel={rowModesModel}
              components={{ Toolbar: isMobile ? undefined : GridToolbar}}
              onRowModesModelChange={handleRowModesModelChange}
              onRowEditStop={handleRowEditStop}
              processRowUpdate={processRowUpdate}
              style={{height: '100%',
            fontSize: isMobile ? '8px' : '10px'}}
              columnHeaderHeight={isDashboard ? 25 : 56}
              rowHeight={isDashboard ? 35 : 52}
              
              
              hideFooter={isDashboard ? true : false}
              // autoHeight={isDashboard ? true : false}
              
            />
          </Box>
        ) : (
          <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="50vh"
          // width="100vw"
      >
          <CircularProgress />
      </Box>  )}
      </Box>
    );
  };

export default Investments