import { Box, Typography, Dialog, DialogContent } from "@mui/material";
import {
  DataGrid,
  GridToolbar,
  GridRowModes,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from "@mui/x-data-grid";
import { useEffect } from "react";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme,CircularProgress } from "@mui/material";
import { useMobile } from '../../MobileContext';
import { useGetInvestorsQuery } from "../../state/api";
import { useUpdateUserMutation } from "../../state/api";
import { useDeleteUserMutation } from "../../state/api";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import Button from "@mui/material/Button";
import AddUserPage from "../forms/addUser";
import { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";

const Users = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useMobile()
  const { data, isLoading } = useGetInvestorsQuery(undefined,{
    refetchOnMountOrArgChange: true,
  });
  const [updateUser, { isLoadingNote, isSuccess, isError }] =
    useUpdateUserMutation();
  const [deleteUser, { isLoadingNotes, isSuccessDelete, isErrorDelete }] =
    useDeleteUserMutation();
  const [open, setOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});

  useEffect(() => {
    if (data) {
      setRows(data); // Assuming 'data' is in the correct format for DataGrid
    }
  }, [data]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUserAdded = (newUser) => {
    setRows((prevRows) => [...prevRows, newUser]);
  };

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    console.log("handle edit click", id);
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => async () => {
    try {
      // If deleteNote is successful, filter out the deleted row
      setRows(rows.filter((row) => row.investorid !== id));
      // Assuming deleteNote makes an API call and returns a promise
      await deleteUser(id);
    } catch (error) {
      console.error("Failed to delete note", error);
      // Handle any errors, e.g., showing an error message to the user
    }
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
    const editedRow = rows.find((row) => row.investorid === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.investorid !== id));
    }
  };

  const processRowUpdate = async (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    try {
      await updateUser(updatedRow).unwrap();
    } catch (error) {
      console.error("Failed to update note", error);
    }
    setRows(
      rows.map((row) =>
        row.investorid === newRow.investorid ? updatedRow : row
      )
    );
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns = [
    { field: "investorid", headerName: "ID", flex: 0.5 },
    // { field: "registrarId", headerName: "Registrar ID" },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      cellClassName: "name-column--cell",
      editable: true,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      headerAlign: "left",
      align: "left",
      editable: true,
    },
    {
      field: "investmentprofile",
      headerName: "Investment Profile",
      flex: 1,
      type: "singleSelect",
      valueOptions: ["Preferred", "Products", "Other"],
      editable: true,
    },
    {
      field: "id",
      headerName: "ID",
      flex: 1,
      editable: true,
    },
    {
      field: "iban",
      headerName: "IBAN",
      flex: 1,
      editable: true,
    },
    {
      field: "bankname",
      headerName: "Bank",
      flex: 1,
      editable: true,
    },
    {
      field: "address",
      headerName: "Address",
      flex: 1,
      editable: true,
    },
    {
      field: "role",
      headerName: "Role",
      flex: 1,
      type: "singleSelect",
      valueOptions: ["admin", "investor", "viewer"],
      editable: true,
    },
    {
      field: "phonenumber",
      headerName: "Phone #",
      flex: 1,
      editable: true,
    },

    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Users" />

        <Box>
          <Button
            onClick={handleClickOpen}
            sx={{
              backgroundColor: colors.greenAccent[500],
              color: colors.grey[100],
              fontSize: "10px",
              fontWeight: "bold",
              padding: "10px 20px",
              mr: "20px",
            }}
          >
            <AddOutlinedIcon sx={{ mr: "10px" }} />
            Add User
          </Button>
          <Dialog open={open} onClose={handleClose}>
            <DialogContent>
              <AddUserPage
                onClose={handleClose}
                onUserAdded={handleUserAdded}
              />
            </DialogContent>
          </Dialog>
        </Box>
      </Box>
      {data || !isLoading ? (
        <Box
          m="40px 0 0 0"
          height="75vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.greenAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.greenAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.greenAccent[700],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.greenAccent[200]} !important`,
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${colors.grey[100]} !important`,
            },
          }}
        >
          <DataGrid
            rows={rows}
            columns={columns}
            editMode="row"
            getRowId={(row) => row.investorid}
            rowModesModel={rowModesModel}
            components={{ Toolbar: GridToolbar }}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            style={{height: '100%',
            fontSize: isMobile ? '8px' : '10px'}}
          />
        </Box>
      ) : (
        <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="50vh"
        // width="100vw"
    >
        <CircularProgress />
    </Box>       )}
    </Box>
  );
};

export default Users;
